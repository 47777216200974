import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import cosmobilisDeckReducer from "core/store/reducer";

const persistConfig = {
  key: "cosmobilisDeck",
  storage,
  blacklist: [
    "error",
    "whiteMenu",
    "menuOpened",
    "sendCode",
    "pdfDatas",
    "getTheDeck",
    "getTheTeaser",
  ],
};

const rootReducer = combineReducers({
  fuzio: persistReducer(persistConfig, cosmobilisDeckReducer),
});

//const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    }),
});

export default store;
