import React from "react";
import { useEffect } from "react";
import { Result } from "antd";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { useIntl } from "react-intl";
import useWindowSize from "hooks/utils/useWindowSize";
import { useDispatch } from "react-redux";

import { Card } from "components/";
import { font } from "theme/";
import { showLangHeader, toggleMenuColor } from "core/store/actions";

const bkg = require("assets/images/onepage/home/bkg.jpg");
const GlobalWrapper = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  min-height: ${(props) => props.size.height}px;
  align-items: center;
  justify-content: center;
  background: url("${bkg}") center center;
  background-size: cover;
  .ant-card {
    font-size: ${font.size.lgxxs}px;
    width: ${(props) => (props.isMobile ? "95%" : "55%")};
  }
`;

const StyledResult = styled(Result)`
  .ant-result-title,
  .ant-result-subtitle {
    color: ${(props) => props.theme.greys.dark1};
  }
`;

const NoMatch = () => {
  const intl = useIntl();
  const size = useWindowSize();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showLangHeader(true));
    dispatch(toggleMenuColor(true));
  }, []);

  return (
    <GlobalWrapper isMobile={isMobile} size={size}>
      <Card>
        <StyledResult
          title={intl.formatMessage({ id: "views.notMatch.Result.title" })}
          status="404"
          subTitle={intl.formatMessage({
            id: "views.notMatch.Result.subtTitle",
          })}
        />
      </Card>
    </GlobalWrapper>
  );
};

export default NoMatch;
