import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";

import { useState } from "react";
import { useIntl } from "react-intl";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import useWindowSize from "hooks/utils/useWindowSize";
import AnyChart from "anychart-react";
import theme from "assets/theme";
import { calculateAlphaColor } from "core/utils/colors";
import anychart from "anychart";
import { Row, Col, Button } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

import { toggleMenuColor } from "core/store/actions";

const color = "#67396d";

const GlobalWrapper = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  min-height: ${(props) => props.size?.height}px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  padding: ${isMobile ? "70px 5px" : "70px"};
`;

const CardTitle = styled.div`
  padding: 10px ${isMobile ? 10 : 40}px;
  margin: 0 ${isMobile ? 0 : 20}px;
  text-align: center;
  min-height: 110px;
  background: ${(props) => props.theme.colors.primary.background};
  color: ${(props) => props.theme.colors.primary.foreground};
  display: flex;
  align-items: center;
  span {
    font-weight: 900;
    margin: 0;
    border-left: 2px solid ${(props) => props.theme.colors.primary.foreground};
    padding-left: 8px;
  }
  flex-direction: column;
  justify-content: center;
`;

const colors = {
  scaler: {
    border: `1px solid ${theme.colors.primary.background}`,
  },
  name: {
    color: theme.colors.primary.background,
    "font-size": "12px",
    "font-weight": "300",
  },
  post: {
    "font-weight": "600",
    "font-size": "12px",
    color: theme.colors.primary.background,
  },
  post2: {
    "font-size": "12px",
    color: theme.colors.primary.background,
  },
  prefixpost: { "font-weight": "bold", color: theme.colors.primary.background },
};

let postStyles = "";
for (const [key, value] of Object.entries(colors.post)) {
  postStyles += `${key}: ${value};`;
}
let postStyles2 = "";
for (const [key, value] of Object.entries(colors.post2)) {
  postStyles2 += `${key}: ${value};`;
}

const renderPost = (post) => `<span style="${postStyles}">${post}</span>`;

const renderPost2 = (post, post2) =>
  `<span style="${postStyles2}">${post}</span><br/><span style="${postStyles}">${post2}</span>`;

const renderPost3 = (post, post2, post3) =>
  `<span style="${postStyles2}">${post}</span><br/><span style="${postStyles}">${post2}</span><span style="${postStyles2}" >${post3}</span>`;

let prefixpostStyles = "";
for (const [key, value] of Object.entries(colors.prefixpost)) {
  prefixpostStyles += `${key}: ${value};`;
}
const renderPrefixPost = (post) =>
  `<span style="${prefixpostStyles}">${post}</span>`;

let nameStyles = "";
for (const [key, value] of Object.entries(colors.name)) {
  nameStyles += `${key}: ${value};`;
}

const PageTitle = styled.h3`
  font-size: ${isMobile ? 22 : 25}px;
  text-align: center;
  max-width: 75%;
  margin: 30px auto 20px;
  font-weight: 600;
  span {
    font-weight: 800;
  }
`;

function Home() {
  const [displayedChart, setDisplayedChart] = useState(1);
  const intl = useIntl();
  const dispatch = useDispatch();

  /// Datas
  const datas = [
    {
      name: ` `,
      id: "firstLevel",
      key: 0,
      post: `${renderPost(
        intl.formatMessage({ id: "views.team.chart1.2.1.post" })
      )}`,
      fill: calculateAlphaColor(color, 0.6),
      children: [
        {
          name: "G. Sprenger",
          id: "secondLevel",
          key: 1,
          post: `${renderPrefixPost(
            intl.formatMessage({ id: "views.team.chart1.3.1.prefix" })
          )} ${renderPost(
            intl.formatMessage({ id: "views.team.chart1.3.1.post" })
          )}`,
          fill: calculateAlphaColor(color, 0.45),
          children: [
            {
              key: 2,
              name: "S. Barlois",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.1.1.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
            },
            {
              key: 3,
              name: "T. Gauvain",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.1.2.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
            },
            {
              key: 4,
              name: "C. Pineau",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.1.3.post" })
              )}`,
              post2: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.1.3-2.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
              children: [
                {
                  key: 5,
                  name: "C. Pineau",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.1.3.1.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 6,
                  name: "C. Pineau",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.1.3.2.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },

                {
                  key: 7,
                  name: "E. Rudolf & E. Polèse",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.1.3.5.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 8,
                  name: "A. Goudin",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.1.3.6.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
              ],
            },
            {
              key: 9,
              name: "G. Sprenger",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.1.5.post" })
              )}`,
              post2: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.1.5-2.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
              children: [
                {
                  key: 10,
                  name: "G. Sprenger",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.1.5.1.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 11,
                  name: "A. Rodrigues",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.1.5.2.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 12,
                  name: "J. Damis",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.1.5.3.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 13,
                  name: "S.Tinti",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.1.5.4.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 14,
                  name: "tbd",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.1.5.5.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
              ],
            },
            {
              key: 15,
              name: "J.-L. Savigny",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.1.6.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
            },
          ],
        },
        {
          key: 16,
          name: "C. Gomes",
          post: `${renderPrefixPost(
            intl.formatMessage({ id: "views.team.chart1.3.2.prefix" })
          )} ${renderPost(
            intl.formatMessage({ id: "views.team.chart1.3.2.post" })
          )}`,
          fill: calculateAlphaColor(color, 0.45),
          children: [
            {
              key: 17,
              name: "...",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.2.1.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
              children: [
                {
                  key: 18,
                  name: "P. Drevet",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.2.1.1.post" })
                  )}`,

                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 19,
                  name: "tbd",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.2.1.2.post" })
                  )}`,

                  fill: calculateAlphaColor(color, 0.15),
                },
              ],
            },
            {
              key: 20,
              name: "...",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.2.4.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
              children: [
                {
                  key: 21,
                  name: "J.-L. Savigny",
                  post: `${renderPost("FLEETWAY")}`,

                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 22,
                  name: "R. Mazurk",
                  post: `${renderPost("USED CARS B.U")}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 23,
                  name: "R. Mazurk",
                  post: `${renderPost("ELITE AUTO")}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 24,
                  name: "tbd",
                  post: `${renderPost("AFTERSALES PERFORMANCE B.U")}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 25,
                  name: "S. Roinet",
                  post: `${renderPost("FINANCING B.U")}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 26,
                  name: "P. Josselin",
                  post: `${renderPost("MARKETING & BDC")}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 27,
                  name: "S. Pourrat",
                  post: `${renderPost("CFO")}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 28,
                  name: "M. Eddahbi",
                  post: `${renderPost("NETWORK & REAL ESTATE")}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
              ],
            },
            {
              key: 29,
              name: "...",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.2.2.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),

              children: [
                {
                  key: 30,
                  name: "O. Bouvarel",
                  post: `${renderPost3(
                    "MANAGER GÉNÉRAL RHÔNE – ISÈRE – LOIRE",
                    "VW GROUP",
                    " - NISSAN"
                  )}`,

                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 31,
                  name: "R. Robin",
                  post: `${renderPost2(
                    "MANAGER GÉNÉRAL LÉMAN – HAUTE-SAVOIE",
                    "PEUGEOT"
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 32,
                  name: "M. Gex",
                  post: `${renderPost2("MANAGER GÉNÉRAL GRENOBLE", " ")}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 33,
                  name: "C. Frantz",
                  post: `${renderPost2(
                    "MANAGER GÉNÉRAL IDF – PARIS",
                    "BMW GROUP"
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 34,
                  name: "A. Vitali",
                  post: `${renderPost2(
                    "MANAGER GÉNÉRAL LORRAINE – BOURGOGNE",
                    "RENAULT – VW GROUP"
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 35,
                  name: "G. Ni Castro",
                  post: `${renderPost2(
                    "MANAGER GÉNÉRAL VAUCLUSE",
                    "VW – AUDI"
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 36,
                  name: "E .Potola",
                  post: `${renderPost3(
                    "MANAGER GÉNÉRAL CÔTE D’AZUR",
                    "MERCEDES",
                    " - FORD - NISSAN"
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
              ],
            },
            {
              key: 37,
              name: "...",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.2.3.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
              children: [
                {
                  key: 38,
                  name: "R. Robin",
                  post: `${renderPost2(
                    "MANAGER GÉNÉRAL SUISSE",
                    "STELLANTIS – FORD"
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 39,
                  name: "E. Potola & D. Leroy",
                  post: `${renderPost2(
                    "MANAGER GÉNÉRAL BELGIQUE",
                    "MERCEDES BRUXELLES"
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 40,
                  name: "F. Ropert & A. Lopez",
                  post: `${renderPost2(
                    "MANAGER GÉNÉRAL ESPAGNE",
                    "BMW MADRID"
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 41,
                  name: "G. De Cesaris",
                  post: `${renderPost2(
                    "MANAGER GÉNÉRAL ITALIE",
                    "BMW MILAN "
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 42,
                  name: "A. Vitali & L. Subias",
                  post: `${renderPost2(
                    "MANAGER GÉNÉRAL ESPAGNE",
                    "RENAULT BARCELONE"
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 43,
                  name: "F. Ropert",
                  post: `${renderPost2("OPÉRATIONS INTERNATIONALES ", " ")}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
              ],
            },
          ],
        },
        {
          key: 44,
          name: "J. Letout",
          post: `${renderPrefixPost(
            intl.formatMessage({ id: "views.team.chart1.3.3.prefix" })
          )} ${renderPost(
            intl.formatMessage({ id: "views.team.chart1.3.3.post" })
          )}`,
          fill: calculateAlphaColor(color, 0.45),
          children: [
            {
              key: 45,
              name: "C. Ligeron",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.3.1.post" })
              )}`,
              post2: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.3.1-2.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
              children: [
                {
                  key: 46,
                  name: "J.-P. Perrin",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.3.1.1.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 47,
                  name: "J. Singeot",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.3.1.2.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 48,
                  name: "tdb",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.3.1.3.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 49,
                  name: "J. Brumant",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.3.1.4.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 50,
                  name: "A. Saoud",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.3.1.5.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 51,
                  name: "N. Cecconi",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.3.1.6.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
              ],
            },
            {
              key: 52,
              name: "J. Damis",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.3.2.post" })
              )}`,
              post2: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.3.2-2.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
              children: [
                {
                  key: 53,
                  name: "S. Sommé",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.3.2.1.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 54,
                  name: "S. Sommé",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.3.2.2.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 55,
                  name: "S. Sommé",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.3.2.3.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 56,
                  name: "tbd",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.3.2.4.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
                {
                  key: 57,
                  name: "tbc",
                  post: `${renderPost(
                    intl.formatMessage({ id: "views.team.chart1.3.3.2.5.post" })
                  )}`,
                  fill: calculateAlphaColor(color, 0.15),
                },
              ],
            },
            {
              key: 58,
              name: "J. Brumant",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.3.3.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
            },
          ],
        },
        {
          key: 59,
          name: "F. Leroux",
          post: `${renderPrefixPost(
            intl.formatMessage({ id: "views.team.chart1.3.4.prefix" })
          )} ${renderPost(
            intl.formatMessage({ id: "views.team.chart1.3.4.post" })
          )}`,
          fill: calculateAlphaColor(color, 0.45),
          children: [
            {
              key: 60,
              name: "B. Bachelot",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.4.1.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
            },
            {
              key: 61,
              name: "M. Caputo",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.4.2.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
            },
            {
              key: 62,
              name: "C. Lévêque",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart1.3.4.3.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
            },
          ],
        },
      ],
    },
  ];

  const datas2 = [
    {
      name: `C. Gomes`,
      class: "Scalers / Office <br/><br/>",
      post: `${renderPost(
        intl.formatMessage({ id: "views.team.chart2.1.1.post" })
      )}`,
      id: "firstLevel",
      fill: calculateAlphaColor(color, 0.6),
      children: [
        {
          name: ` `,
          post: `${renderPost(
            intl.formatMessage({ id: "views.team.chart2.2.1.post" })
          )}`,
          fill: calculateAlphaColor(color, 0.45),
          children: [
            {
              name: "C.  Pineau",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart2.2.1.1.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
            },
            {
              name: "H. Lahlaoui",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart2.2.1.2.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
            },
            {
              name: "Y. Amziane",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart2.2.1.3.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
            },
            {
              name: "B. Bodereau",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart2.2.1.4.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
            },
          ],
        },
        {
          name: ` `,
          post: `${renderPost(
            intl.formatMessage({ id: "views.team.chart2.2.2.post" })
          )}`,
          fill: calculateAlphaColor(color, 0.45),
          children: [
            {
              name: "P. Mauchoffé",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart2.2.2.1.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
            },
            {
              name: "A. Austin",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart2.2.2.2.post" })
              )}`,
              fill: calculateAlphaColor(color, 0.25),
            },
            {
              name: "M. Eddahbi",
              post: `${renderPost(
                intl.formatMessage({ id: "views.team.chart2.2.2.3.post" })
              )}`,

              fill: calculateAlphaColor(color, 0.25),
            },
          ],
        },
      ],
    },
  ];

  /////
  const size = useWindowSize();

  const padding = ((size.width / 24) * 26) / 100;

  let stage = anychart.graphics.create();

  let chart2 = anychart.sunburst(datas2, "as-tree");
  chart2.selected().fill(calculateAlphaColor(color, 0.25));
  chart2.padding(-padding);
  chart2.startAngle(-90);
  chart2.labels().position("circular");
  chart2.labels().fontFamily("Lato");
  chart2.hovered().fill(calculateAlphaColor(color, 0.75));
  chart2.tooltip({
    enabled: true,
    useHtml: true,
    format: `{%post}<br/><span>{%name}</span>`,
  });
  chart2.tooltip().fontFamily("Lato");
  chart2.labels({
    useHtml: true,
    format: `{%post}<br/><span style="${nameStyles}">{%name}</span>`,
  });

  chart2.bounds(0, 0, "100%", "95%");

  chart2.listen("pointClick", function (e) {
    const id = e.point.get("id");

    if (id && id === "firstLevel") {
      setDisplayedChart(1);
    }
  });

  /************* */
  const treeData = anychart.data.tree(datas, "as-tree");
  let chart = anychart.sunburst(treeData);

  chart.labels({
    useHtml: true,
    format: `{%post}<br/><span style="${nameStyles}">{%name}</span>`,
  });
  chart.selected().fill(calculateAlphaColor(color, 0.25));
  chart.hovered().fill(calculateAlphaColor(color, 0.75));

  chart.level(3).enabled(false);
  chart.labels().position("circular");
  chart.labels().fontFamily("Lato");
  chart.labels().padding(0);
  chart.padding(-padding);
  chart.startAngle(-45);
  chart.tooltip({
    enabled: true,
    useHtml: true,
    format: `{%post}<br/><span>{%name}</span>`,
  });
  chart.tooltip().fontFamily("Lato");

  chart.bounds(0, 0, "100%", "100%");

  chart.listen("chartDraw", function () {
    const path = chart.getDrilldownPath();

    const length = path.length;

    if (length === 3) {
      chart.level(3).enabled(true);

      // Labels
      if (
        path[2]?.index === 4 ||
        path[2]?.index === 9 ||
        path[2]?.index === 45 ||
        path[2]?.index === 52
      ) {
        chart.level(2).labels({
          useHtml: true,
          format: `{%post2}<br/><span style="${nameStyles}">{%name}</span>`,
        });
      } else {
        chart.level(2).labels({
          useHtml: true,
          format: `{%post}<br/><span style="${nameStyles}">{%name}</span>`,
        });
      }

      // inner radius
      if (
        path[2]?.index === 4 ||
        path[2]?.index === 9 ||
        path[2]?.index === 45 ||
        path[2]?.index === 52
      ) {
        chart.innerRadius(0);
      }
      console.log("path[2]?.index :", path[2]?.index);
      // Angles
      if (path[2]?.index === 4) {
        chart.startAngle(-46);
      } else if (path[2]?.index === 45) {
        chart.startAngle(-30);
      } else if (path[2]?.index === 9 || path[2]?.index === 52) {
        chart.startAngle(-37);
      } else if (path[2]?.index === 17) {
        chart.startAngle(-90);
      } else if (path[2]?.index === 20) {
        chart.startAngle(-23);
      } else if (path[2]?.index === 29) {
        chart.startAngle(-53);
      } else if (path[2]?.index === 37) {
        chart.startAngle(-30);
      } else {
        chart.startAngle(-45);
      }
    } else {
      chart.level(2).labels({
        useHtml: true,
        format: `{%post}<br/><span style="${nameStyles}">{%name}</span>`,
      });
      chart.startAngle(-45);
      chart.level(3).enabled(false);
      chart.innerRadius(isMobile ? "40%" : "15%");
    }
  });

  chart.listen("pointClick", function (e) {
    const id = e.point.get("id");
    if (id && id === "firstLevel") {
      setDisplayedChart(2);
    }
  });

  const centerLabel = anychart.standalones.label();
  centerLabel.useHtml(true);
  centerLabel.text(
    `${renderPost(
      intl.formatMessage({ id: "views.team.chart1.1.1.post" })
    )}<br/><span style="${nameStyles}">C. Gomes</span>`
  );
  centerLabel.width("100%");
  centerLabel.height("100%");
  centerLabel.hAlign("center");
  centerLabel.vAlign("middle");
  chart.center().content(centerLabel);

  const reset = () => {
    var item = treeData.search("id", "firstLevel");
    setDisplayedChart(1);
    chart.drillTo(item);
  };

  useLayoutEffect(() => {
    dispatch(toggleMenuColor(false));
  }, []);
  return (
    <>
      <GlobalWrapper size={size}>
        <PageTitle>
          {intl.formatMessage(
            { id: "views.team.screen.1.pageTitle" },
            {
              span: (...chunks) => <span>{chunks}</span>,
            }
          )}
        </PageTitle>
        <Row
          align="middle"
          gutter={20}
          style={{ width: "90%" }}
          justify="space-around"
        >
          <Col span={isMobile ? 24 : 8}>
            <Row gutter={[10, 20]} justify={"center"}>
              <Col span={24}>
                <CardTitle>
                  <span>
                    {intl.formatMessage({ id: "views.team.card1.title" })}
                  </span>
                  <br />
                  Jean-Louis MOSCA / Xavier COTELLE / <br /> Carlos GOMES /
                  Jean-Claude PUERTO /<br />
                  <br />
                  Christophe PINEAU / Hamid LAHLAOUI / IDIA-CACF
                </CardTitle>
              </Col>
              <Col span={24}>
                <CardTitle>
                  <span>
                    {intl.formatMessage({ id: "views.team.card2.title" })}
                  </span>
                  <br />
                  Jean-Louis MOSCA / Xavier COTELLE
                </CardTitle>
              </Col>
            </Row>
          </Col>
          <Col
            span={isMobile ? 24 : 16}
            style={{ height: isMobile ? 400 : size?.height - 200 }}
          >
            <AnyChart
              instance={stage}
              charts={[displayedChart === 1 ? chart : chart2]}
              height={isMobile ? 400 : size?.height - 200}
              credits={{
                enabled: false,
                html: true,
              }}
            ></AnyChart>
            <Row
              style={{
                marginTop: isMobile ? -50 : -100,
                width: isMobile ? "95%" : "100%",
              }}
              justify="end"
            >
              <Col>
                <Button onClick={reset} icon={<ReloadOutlined />}>
                  {!isMobile &&
                    intl.formatMessage({ id: "views.team.charts.button" })}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </GlobalWrapper>
    </>
  );
}

export default Home;
