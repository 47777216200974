import { useDispatch, useSelector } from "react-redux";
import { isMobile, isBrowser } from "react-device-detect";
import { useIntl } from "react-intl";
import useWindowSize from "hooks/utils/useWindowSize";
import useGeo from "hooks/utils/useGeo";

import styled from "styled-components";
import { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { Result, Button, message } from "antd";

import "react-phone-number-input/style.css";

import PhoneInput, {
  formatPhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

import { Card, CodeInputs } from "components/";
import { ROUTES } from "constants/routes";

import {
  signIn,
  postPhone,
  postCode,
  setToken,
  showLangHeader,
  sendCode,
  toggleMenuColor,
  setLang,
} from "core/store/actions";
import {
  showInputPhoneSelector,
  showInputCodeSelector,
  showRequestCodeButtonSelector,
} from "core/store/selectors";

import { font } from "theme/";

const bkg = require("assets/images/onepage/home/bkg.jpg");

const GlobalWrapper = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  min-height: ${(props) => props.size.height}px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.primary.background};
  background: url("${bkg}") center center;
  background-size: cover;
  .ant-card {
    font-size: ${isMobile ? font.size.lgxxs : font.size.lgxxs + 4}px;
    width: ${(props) => (props.isMobile ? "95%" : "55%")};
  }
`;

const UserIdenty = styled.span`
  text-transform: capitalize;
  font-size: ${font.size.lgxs}px;
  font-family: ${font.familyBold};
  color: ${(props) =>
    props.light ? props.theme.greys.light4 : props.theme.greys.dark1};
`;

const UserPhone = styled.span`
  font-size: ${font.size.lgxl}px;
  font-family: ${font.familyBold};
  color: ${(props) =>
    props.light ? props.theme.greys.light4 : props.theme.greys.dark1};
  display: inline-block;
`;

const CodeInputsWrapper = styled.div`
  justify-content: center;
  display: flex;
`;
const StyledPhoneInput = styled(PhoneInput)`
  color: #000;
`;

const RenewCodeButton = styled(Button)`
  margin-top: 10px;
  color: ${(props) => props.theme.colors.primary.background};
  font-size: ${font.size.lg}px;
  &.ant-btn-primary[disabled] {
    background: none;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: ${isMobile ? "90%" : "500px"};
  p {
    color: #fff;
    font-size: ${isMobile ? font.size.lgxxs : font.size.lgxs}px;
    text-align: left;
    width: 100%;
  }
`;

const Login = () => {
  const dispatch = useDispatch();

  let { token } = useParams();
  const size = useWindowSize();
  const geoInfos = useGeo();

  let navigate = useNavigate();
  const location = useLocation();
  const intl = useIntl();

  const loading = useSelector((state) => state.fuzio.loading);
  const error = useSelector((state) => state.fuzio.error);
  const user = useSelector((state) => state.fuzio.user);
  const phone = useSelector((state) => state.fuzio.user?.sTelephone);
  const isNotVerified = useSelector((state) => state.fuzio.user.bTokenExpire);

  const showInputPhone = useSelector(showInputPhoneSelector);
  const showInputCode = useSelector(showInputCodeSelector);

  const showRequestCodeButton = useSelector(showRequestCodeButtonSelector);

  const [localPhone, setLocalPhone] = useState();
  const [localCode, setLocalCode] = useState();
  // console.log("localCode:", localCode);

  const [submitCodeButtonDisabled, setSubmitCodeButtonDisabled] =
    useState(true);

  const sendPhone = () => {
    if ((localPhone && isValidPhoneNumber(localPhone)) || phone) {
      dispatch(
        postPhone({
          token: formatPhoneNumber(localPhone).replace(/\s/g, "") || phone,
          phone: localPhone || phone,
        })
      );
      dispatch(
        setToken(formatPhoneNumber(localPhone).replace(/\s/g, "") || phone)
      );
      dispatch(sendCode(true));
    }
  };

  const checkCode = () => {
    dispatch(
      postCode({
        token: formatPhoneNumber(localPhone).replace(/\s/g, "") || phone,
        code: localCode,
      })
    );
  };

  const handleChangeCode = (code) => {
    //console.log("handleChangeCode:", code);
    setLocalCode(code.join(""));
    setSubmitCodeButtonDisabled(code.includes(undefined) || code.includes(""));
  };

  const RenderUser = (props) => {
    const { light } = props;
    return (
      <>
        <UserIdenty light={light}>{user.sPrenom}</UserIdenty>,
      </>
    );
  };

  useEffect(() => {
    if (error) {
      if (error === "Token inconnu.") {
        message.error(intl.formatMessage({ id: "views.login.error" }));
      } else {
        message.error(error);
      }

      if (error === "Token inconnu.") {
        navigate("/login", { replace: true });
      }
    }
  }, [error]);

  useEffect(() => {
    if (!isNotVerified) {
      dispatch(
        setToken(formatPhoneNumber(localPhone).replace(/\s/g, "") || phone)
      );
      navigate(ROUTES.home.path, { replace: true });
    }
  }, [isNotVerified, navigate]);

  useEffect(() => {
    if (geoInfos && geoInfos.languages) {
      /*const aLangs = geoInfos.languages.split(",");
      const displayLang = aLangs[0].replace("-", "_");

      displayLang &&
        (displayLang === "fr_FR" || displayLang === "en_GB") &&
        !token &&
        dispatch(setLang(displayLang));*/
    }
  }, [geoInfos]);
  useEffect(() => {
    dispatch(showLangHeader(true));
    dispatch(toggleMenuColor(true));
  }, []);

  useLayoutEffect(() => {
    if (token) {
      dispatch(signIn({ token, url: location.pathname }));
    }
  }, [token]);
  //  <RenderUser light />
  return (
    <GlobalWrapper isMobile={isMobile} size={size}>
      {showInputPhone && (
        <Wrapper>
          <p>{intl.formatMessage({ id: "views.login.Card1.sentence4" })}</p>

          <p>
            {intl.formatMessage({ id: "views.login.Card1.sentence2" })}
            <br />
            {intl.formatMessage({ id: "views.login.Card1.sentence5" })}

            <br />
          </p>

          <Card
            actions={[
              <Button
                onClick={sendPhone}
                loading={loading}
                type="primary"
                size="large"
                shape="round"
              >
                {intl.formatMessage({ id: "views.login.Card.Button" })}
              </Button>,
            ]}
            title={intl.formatMessage({
              id: "views.login.Card1.title",
            })}
            style={{ width: isMobile ? "90%" : "500px" }}
          >
            <StyledPhoneInput
              placeholder={intl.formatMessage({
                id: "views.login.PhoneInput.placholder",
              })}
              onChange={setLocalPhone}
              value={localPhone}
              disabled={loading}
              defaultCountry={"FR"}
            />
          </Card>
        </Wrapper>
      )}
      {showRequestCodeButton && (
        <Card
          actions={[
            <Button
              onClick={sendPhone}
              loading={loading}
              type="primary"
              size="large"
              shape="round"
            >
              {intl.formatMessage({ id: "views.login.Card2.sentence1" })}

              {isBrowser &&
                ` ${intl.formatMessage({
                  id: "views.login.Card2.sentence2",
                })} ${
                  user.sTelephone && isValidPhoneNumber(user?.sTelephone)
                    ? formatPhoneNumber(user?.sTelephone)
                    : user?.sTelephone
                }`}
            </Button>,
          ]}
          title={intl.formatMessage({
            id: "views.login.Card1.title",
          })}
          style={{ width: isMobile ? "90%" : "500px" }}
        >
          <RenderUser />
          <br />
          {intl.formatMessage({ id: "views.login.Card2.sentence3" })}
          <br />
          <UserPhone>
            {user.sTelephone && isValidPhoneNumber(user?.sTelephone)
              ? formatPhoneNumber(user?.sTelephone)
              : user?.sTelephone}
          </UserPhone>
        </Card>
      )}

      {showInputCode && (
        <Card
          actions={[
            <Button
              onClick={checkCode}
              type="primary"
              size="large"
              disabled={submitCodeButtonDisabled}
              shape="round"
            >
              {intl.formatMessage({ id: "views.login.Card.Button2" })}
            </Button>,
          ]}
        >
          <p>
            <RenderUser />
          </p>
          <p>
            {intl.formatMessage({ id: "views.login.Card3.sentence1" })}{" "}
            <UserPhone>
              {user.sTelephone && isValidPhoneNumber(user?.sTelephone)
                ? formatPhoneNumber(user?.sTelephone)
                : user?.sTelephone}
            </UserPhone>
          </p>
          <CodeInputsWrapper>
            <CodeInputs length={6} onChange={handleChangeCode} />
          </CodeInputsWrapper>
          <RenewCodeButton
            onClick={sendPhone}
            loading={loading}
            type="link"
            size="small"
            block
          >
            {intl.formatMessage({ id: "views.login.Card3.sentence2" })}
            {isBrowser &&
              ` ${intl.formatMessage({
                id: "views.login.Card3.sentence3",
              })} ${
                user.sTelephone && isValidPhoneNumber(user?.sTelephone)
                  ? formatPhoneNumber(user?.sTelephone)
                  : user?.sTelephone
              }`}
          </RenewCodeButton>
        </Card>
      )}

      {
        // Error unknown token
        error && !user && <Result status="error" title={error} />
      }
    </GlobalWrapper>
  );
};

export default Login;

/*
  
   {
        // Loading signin
        loading && !user.bTokenVerifie && (
          <Wrapper>
            <Card>
              <SpinWrapper>
                <Spin size="large" />
              </SpinWrapper>
            </Card>
          </Wrapper>
        )
      }
*/
