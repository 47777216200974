import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import moment from "moment";
import "moment/locale/fr";
import localeAntd_fr from "antd/lib/locale/fr_FR";
import localeAntd_en from "antd/lib/locale/en_GB";

import { translationsFrFr, translationsEnGb } from "locales";
import theme from "theme";

const langs = [
  {
    id: "fr_FR",
    locale: "fr",
    messages: translationsFrFr,
    currency: "EUR",
    localeAntd: localeAntd_fr,
  },
  {
    id: "en_GB",
    locale: "en",
    messages: translationsEnGb,
    currency: "EUR",
    localeAntd: localeAntd_en,
  },
];

function GlobalContext(props) {
  const { children } = props;
  const lang = useSelector((state) => state.fuzio.lang);
  const langToDisplay = langs.find((el) => el.id === lang);

  const { locale, messages, currency, localeAntd } = langToDisplay;

  moment.locale(locale);

  return (
    <IntlProvider locale={locale} messages={messages} currency={currency}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: theme.colors.primary.background,
          },
        }}
        locale={localeAntd}
      >
        <ThemeProvider theme={theme}>{children}</ThemeProvider>
      </ConfigProvider>
    </IntlProvider>
  );
}

export default GlobalContext;
