import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useState } from "react";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { Row, Col, Space } from "antd";
import {
  ArrowRightOutlined,
  ArrowDownOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";

import useWindowSize from "hooks/utils/useWindowSize";
import { toggleMenuColor } from "core/store/actions";

import { font } from "theme";

import { ReactComponent as Bank } from "assets/svg/Bank.svg";
import { ReactComponent as Umbrella } from "assets/svg/Umbrella.svg";
import { ReactComponent as CarRepair } from "assets/svg/CarRepair.svg";
import { ReactComponent as HeartHand } from "assets/svg/HeartHand.svg";
import { ReactComponent as GreenEnergy } from "assets/svg/GreenEnergy.svg";
import { ReactComponent as Recycling } from "assets/svg/Recycling.svg";
import { ReactComponent as Factory } from "assets/svg/Factory.svg";
import { ReactComponent as Calculate } from "assets/svg/Calculate.svg";
import { ReactComponent as Circle2 } from "assets/svg/Circle2.svg";
import { ReactComponent as Person } from "assets/svg/Person.svg";
import { ReactComponent as Phone } from "assets/svg/Phone.svg";

import { ReactComponent as Ucar } from "assets/svg/Ucar.svg";
import { ReactComponent as Bee2link } from "assets/svg/Bee2link.svg";
import { ReactComponent as ByMyCar } from "assets/svg/ByMyCar.svg";
import { ReactComponent as Cosmobilis } from "assets/svg/Cosmobilis.svg";
import { ReactComponent as EnVoitureSimone } from "assets/svg/EnVoitureSimone.svg";
import { ReactComponent as FleetWay } from "assets/svg/FleetWay.svg";
import { ReactComponent as Marcel } from "assets/svg/Marcel.svg";
import { ReactComponent as CosmobilisMobility } from "assets/svg/CosmobilisMobility.svg";

const img1_en = require("images/platform/en/img-1.png");
const img1_fr = require("images/platform/fr/img-1.png");

const img2_en = require("images/platform/en/img-2.png");
const img2_fr = require("images/platform/fr/img-2.png");

const img_en = [img1_en, img2_en];
const img_fr = [img1_fr, img2_fr];

const color1 = "#67396d";
const color2_1 = "#98799c";
const color2_2 = "#e1d7e2";
const color2_3 = "#33102f";

const color3_1 = "#8896ae";
const color3_2 = "#e7eaef";
const color3_3 = "#668dc2";

const color4_1 = "#699ad0";
const color4_2 = "#e0ebf6";

/*const GlobalWrapper = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  min-height: ${(props) => props.size.height}px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  padding: ${isMobile ? "70px 0" : "70px 0"};
  cursor: pointer;
  img:hover {
    opacity: 0.5;
  }
`;*/

const GlobalWrapper = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  min-height: ${(props) => props.size.height}px;

  justify-content: center;
  flex-direction: column;
  overflow: auto;
  padding: ${isMobile ? "70px 0" : "70px "};
  cursor: pointer;
  img:hover {
    opacity: 0.5;
  }
`;

const PageTitle = styled.h3`
  font-size: ${isMobile ? 22 : 25}px;
  text-align: center;
  max-width: 75%;
  margin: 0 auto 10px;
  span {
    font-weight: 800;
  }
`;

const Img = styled.img`
  max-width: ${isMobile ? 100 : 90}%;
  margin-bottom: ${isMobile ? 50 : 10}px;
`;

const AutomobiltyWrapper = styled.div`
  width: 100%;
`;

const AutomobiltyTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${color1};
  color: ${(props) => props.theme.colors.primary.foreground};
  font-size: ${font.size.lgsm}px;
  font-weight: 600;
  text-align: center;
  padding: 10px;
`;

const AutomobiltyContentWrapper = styled.div`
  border: 3px dotted ${color1};
  border-top-width: 0;
  padding: 20px;
`;

const AutomobiltyContentTitle = styled.div`
  background: ${(props) => props.color};
  color: ${(props) => props.theme.colors.primary.foreground};
  text-transform: uppercase;
  font-size: ${font.size.lg}px;
  font-weight: 600;
  text-align: center;
  padding: 10px;
  font-size: ${font.size.base}px;
  span {
    font-size: ${font.size.lgxxs}px;
  }
`;

const BlockTitle = styled.div`
  color: ${(props) => props.color};
  border-left: 2px solid ${(props) => props.color};
  padding-left: 8px;
  text-transform: uppercase;
  font-size: ${font.size.lg}px;
  font-weight: 600;
  text-align: left;
  margin: 0 0 15px 0;
`;
const AutomobiltyContent = styled.div`
  background: ${(props) => props.color};
  font-size: ${font.size.lg}px;
  font-weight: 600;
  padding: 10px;
  width: 100%;
`;

const BlocItem = styled.div`
  padding: 10px;
  border: 1px solid #797479;
  display: flex;
  flex: 1;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  svg {
    width: 30px;
    height: 30px;

    fill: ${(props) => props.color};
  }
`;
const BlocItemTitle = styled.div`
  text-transform: uppercase;
  font-size: ${font.size.lg}px;
  :not(:last-child) {
    margin-right: 10px;
  }
`;

const Number = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 40px;
  background: ${color1};
  color: ${(props) => props.theme.colors.primary.foreground};
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
`;

const Graf2ItemWrapper = styled.div`
  justify-content: center;
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  justify-content: space-around;
  height: 100%;
  width: 100%;
  svg {
    max-width: 170px;
    margin: 10px;
  }
  padding: 10px;
`;

const ARROW_SIZE = 30;
const ARROW_SPACE_SIZE = 30;

const Blob = styled.div`
  display: inline-block;
  width: 10px;
  height: 10px;
`;

const Graf2Title = styled.span`
  border-left: 2px solid ${(props) => props.theme.colors.primary.foreground};
  padding-left: 8px;
`;

function Home() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const size = useWindowSize();
  const lang = useSelector((state) => state.fuzio.lang);

  const img = lang === "fr_FR" ? img_fr : img_en;

  const [displayOne, setDisplayOne] = useState(true);

  const toggleDisplyaOne = () => {
    setDisplayOne(!displayOne);
  };

  useLayoutEffect(() => {
    dispatch(toggleMenuColor(false));
  }, []);

  // span={18} push={3} pull={3}
  return (
    <GlobalWrapper size={size} onClick={toggleDisplyaOne}>
      {displayOne ? (
        <>
          <PageTitle>
            {intl.formatMessage(
              { id: "views.platform.graf.1.pageTitle" },
              {
                span: (...chunks) => <span>{chunks}</span>,
              }
            )}
          </PageTitle>
          {isMobile ? (
            <Img src={img[0]} />
          ) : (
            <>
              <Row style={{ marginBottom: 10 }}>
                <Col span={24}>
                  <AutomobiltyContent color={color3_2}>
                    <BlockTitle color={color3_3}>
                      {intl.formatMessage({
                        id: "views.platform.graf.1.bloc.1.title",
                      })}
                    </BlockTitle>
                    <Row align={"middle"} justify={"space-around"}>
                      <Col>
                        <BlocItem color={color3_3}>
                          <BlocItemTitle style={{}}>
                            {intl.formatMessage({
                              id: "views.platform.graf.1.bloc.1.item.1",
                            })}
                          </BlocItemTitle>
                          <Bank />
                        </BlocItem>
                      </Col>
                      <Col>
                        <BlocItem color={color3_3}>
                          <BlocItemTitle>
                            {intl.formatMessage({
                              id: "views.platform.graf.1.bloc.1.item.2",
                            })}
                          </BlocItemTitle>
                          <Umbrella />
                        </BlocItem>
                      </Col>
                      <Col>
                        <BlocItem color={color3_3}>
                          <BlocItemTitle>
                            {intl.formatMessage({
                              id: "views.platform.graf.1.bloc.1.item.3",
                            })}
                          </BlocItemTitle>
                          <CarRepair />
                        </BlocItem>
                      </Col>
                      <Col>
                        <BlocItem color={color3_3}>
                          <BlocItemTitle>
                            {intl.formatMessage({
                              id: "views.platform.graf.1.bloc.1.item.4",
                            })}
                          </BlocItemTitle>
                          <HeartHand />
                        </BlocItem>
                      </Col>
                      <Col>
                        <BlocItem color={color3_3}>
                          <BlocItemTitle>
                            {intl.formatMessage({
                              id: "views.platform.graf.1.bloc.1.item.5",
                            })}
                          </BlocItemTitle>
                          <GreenEnergy />
                        </BlocItem>
                      </Col>
                      <Col>
                        <BlocItem color={color3_3}>
                          <BlocItemTitle>
                            {intl.formatMessage({
                              id: "views.platform.graf.1.bloc.1.item.6",
                            })}
                          </BlocItemTitle>
                          <Recycling />
                        </BlocItem>
                      </Col>
                      <Col>
                        <BlocItem color={color3_3}>
                          <EllipsisOutlined />
                        </BlocItem>
                      </Col>
                    </Row>
                  </AutomobiltyContent>
                </Col>
              </Row>
              <Row align={"stretch"}>
                <Col span={5}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <AutomobiltyContent color={color3_2}>
                      <BlockTitle color={color3_3}>
                        {intl.formatMessage({
                          id: "views.platform.graf.1.bloc.2.title",
                        })}
                      </BlockTitle>
                      <Space direction={"vertical"} style={{ width: "100%" }}>
                        <BlocItem color={color3_3}>
                          <BlocItemTitle>
                            {intl.formatMessage({
                              id: "views.platform.graf.1.bloc.2.item.1",
                            })}
                          </BlocItemTitle>
                          <Factory />
                        </BlocItem>
                        <BlocItem color={color3_3}>
                          <BlocItemTitle>
                            {intl.formatMessage({
                              id: "views.platform.graf.1.bloc.2.item.2",
                            })}
                          </BlocItemTitle>
                          <Calculate />
                        </BlocItem>
                        <BlocItem color={color3_3}>
                          <BlocItemTitle>
                            {intl.formatMessage({
                              id: "views.platform.graf.1.bloc.2.item.3",
                            })}
                          </BlocItemTitle>
                          <Circle2 />
                        </BlocItem>
                        <BlocItem color={color3_3}>
                          <BlocItemTitle>
                            {intl.formatMessage({
                              id: "views.platform.graf.1.bloc.2.item.4",
                            })}
                          </BlocItemTitle>
                          <Person />
                        </BlocItem>
                        <BlocItem
                          color={color3_3}
                          style={{ justifyContent: "center" }}
                        >
                          <EllipsisOutlined />
                        </BlocItem>
                      </Space>
                    </AutomobiltyContent>
                    <Space direction="vertical" size={ARROW_SPACE_SIZE}>
                      <ArrowRightOutlined style={{ fontSize: ARROW_SIZE }} />
                      <ArrowRightOutlined style={{ fontSize: ARROW_SIZE }} />
                    </Space>
                  </div>
                </Col>
                <Col span={14}>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Space size={ARROW_SPACE_SIZE}>
                      <ArrowDownOutlined style={{ fontSize: ARROW_SIZE }} />
                      <ArrowDownOutlined style={{ fontSize: ARROW_SIZE }} />
                    </Space>
                  </div>
                  <AutomobiltyWrapper>
                    <AutomobiltyTitle>
                      {intl.formatMessage({
                        id: "views.platform.graf.1.bloc.central.title",
                      })}
                      <Blob
                        className="blob"
                        style={{ width: 30, height: 30, marginBlock: -30 }}
                      />
                    </AutomobiltyTitle>
                    <AutomobiltyContentWrapper>
                      <Row align={"middle"} style={{ marginBottom: 20 }}>
                        <Col span={2}>
                          <Number>1</Number>
                        </Col>
                        <Col span={20}>
                          {intl.formatMessage({
                            id: "views.platform.graf.1.bloc.central.item.1",
                          })}
                        </Col>
                      </Row>
                      <Row align={"middle"} style={{ marginBottom: 20 }}>
                        <Col span={2}>
                          <Number>2</Number>
                        </Col>
                        <Col span={20}>
                          {intl.formatMessage({
                            id: "views.platform.graf.1.bloc.central.item.2",
                          })}
                        </Col>
                      </Row>
                      <Row align={"middle"}>
                        <Col span={2}>
                          <Number>3</Number>
                        </Col>
                        <Col span={20}>
                          {intl.formatMessage({
                            id: "views.platform.graf.1.bloc.central.item.3",
                          })}
                        </Col>
                      </Row>
                    </AutomobiltyContentWrapper>
                  </AutomobiltyWrapper>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      width: "100%",
                    }}
                  >
                    <Space size={ARROW_SPACE_SIZE}>
                      <ArrowDownOutlined style={{ fontSize: ARROW_SIZE }} />
                      <ArrowDownOutlined style={{ fontSize: ARROW_SIZE }} />
                    </Space>
                  </div>
                  <AutomobiltyContent
                    color={color2_2}
                    style={{ width: "80%", margin: "auto" }}
                  >
                    <BlockTitle color={color2_3}>
                      {intl.formatMessage({
                        id: "views.platform.graf.1.bloc.3.title",
                      })}
                    </BlockTitle>
                    <Row align={"middle"} justify={"space-around"}>
                      <Col>
                        <BlocItem color={color2_3}>
                          <BlocItemTitle>
                            {intl.formatMessage({
                              id: "views.platform.graf.1.bloc.3.item.1",
                            })}
                          </BlocItemTitle>
                        </BlocItem>
                      </Col>
                      <Col>
                        <BlocItem color={color2_3}>
                          <BlocItemTitle>
                            {intl.formatMessage({
                              id: "views.platform.graf.1.bloc.3.item.2",
                            })}
                          </BlocItemTitle>
                        </BlocItem>
                      </Col>
                      <Col>
                        <BlocItem color={color2_3}>
                          <BlocItemTitle>
                            {intl.formatMessage({
                              id: "views.platform.graf.1.bloc.3.item.3",
                            })}
                          </BlocItemTitle>
                        </BlocItem>
                      </Col>
                    </Row>
                  </AutomobiltyContent>
                </Col>
                <Col span={5}>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Space direction="vertical" size={ARROW_SPACE_SIZE}>
                      <ArrowRightOutlined style={{ fontSize: ARROW_SIZE }} />
                      <ArrowRightOutlined style={{ fontSize: ARROW_SIZE }} />
                    </Space>
                    <AutomobiltyContent color={color2_2}>
                      <BlockTitle color={color2_3}>
                        {intl.formatMessage({
                          id: "views.platform.graf.1.bloc.4.title",
                        })}
                      </BlockTitle>
                      <Space direction={"vertical"} style={{ width: "100%" }}>
                        <BlocItem color={color2_3}>
                          <BlocItemTitle>
                            {intl.formatMessage({
                              id: "views.platform.graf.1.bloc.4.item.1",
                            })}
                          </BlocItemTitle>
                          <Phone />
                        </BlocItem>
                        <BlocItem color={color2_3}>
                          <BlocItemTitle>
                            {intl.formatMessage({
                              id: "views.platform.graf.1.bloc.4.item.2",
                            })}
                          </BlocItemTitle>
                          <Phone />
                        </BlocItem>
                        <BlocItem color={color2_3}>
                          <BlocItemTitle>
                            {intl.formatMessage({
                              id: "views.platform.graf.1.bloc.4.item.3",
                            })}
                          </BlocItemTitle>
                          <Phone />
                        </BlocItem>
                      </Space>
                    </AutomobiltyContent>
                  </div>
                </Col>
              </Row>
            </>
          )}
        </>
      ) : (
        <>
          <PageTitle>
            {intl.formatMessage(
              { id: "views.platform.graf.2.pageTitle" },
              {
                span: (...chunks) => <span>{chunks}</span>,
              }
            )}
          </PageTitle>
          {isMobile ? (
            <Img src={img[1]} />
          ) : (
            <>
              <AutomobiltyWrapper>
                <AutomobiltyTitle>
                  <Graf2Title>
                    {intl.formatMessage({
                      id: "views.platform.graf.2.bloc.1.title",
                    })}
                  </Graf2Title>
                  <Blob
                    className="blob"
                    style={{ width: 30, height: 30, marginBlock: -30 }}
                  />
                </AutomobiltyTitle>
                <AutomobiltyContentWrapper>
                  <Row gutter={10} align={"stretch"}>
                    <Col span={4}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          height: "100%",
                        }}
                      >
                        <AutomobiltyContent
                          color={color2_2}
                          style={{ height: "100%", padding: 0 }}
                        >
                          <AutomobiltyContentTitle color={color2_1}>
                            <Graf2Title>
                              {intl.formatMessage({
                                id: "views.platform.graf.2.bloc.2.title",
                              })}
                            </Graf2Title>
                          </AutomobiltyContentTitle>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              height: "90%",
                            }}
                          >
                            <Space
                              direction="vertical"
                              style={{ width: "90%" }}
                            >
                              <>
                                <Graf2ItemWrapper>
                                  <Cosmobilis />
                                  {intl.formatMessage({
                                    id: "views.platform.graf.2.bloc.2.item.1",
                                  })}
                                </Graf2ItemWrapper>
                              </>
                              <>
                                <Graf2ItemWrapper>
                                  <Ucar />
                                  {intl.formatMessage({
                                    id: "views.platform.graf.2.bloc.2.item.2",
                                  })}
                                </Graf2ItemWrapper>
                              </>
                              <>
                                <Graf2ItemWrapper>
                                  <ByMyCar />
                                </Graf2ItemWrapper>
                              </>
                            </Space>
                          </div>
                        </AutomobiltyContent>
                      </div>
                    </Col>
                    <Col span={20}>
                      <div style={{ marginBottom: 15 }}>
                        <AutomobiltyContentTitle color={color4_1}>
                          <Graf2Title>
                            {intl.formatMessage({
                              id: "views.platform.graf.2.bloc.3.title",
                            })}
                          </Graf2Title>
                        </AutomobiltyContentTitle>
                        <AutomobiltyContent color={color4_2}>
                          <Graf2ItemWrapper>
                            <Bee2link style={{ width: 200 }} />
                            {intl.formatMessage({
                              id: "views.platform.graf.2.bloc.3.item.1",
                            })}
                          </Graf2ItemWrapper>
                        </AutomobiltyContent>
                      </div>
                      <AutomobiltyContentTitle color={color3_1}>
                        <Graf2Title>
                          {intl.formatMessage({
                            id: "views.platform.graf.2.bloc.4.title",
                          })}
                        </Graf2Title>
                        <br />
                        {intl.formatMessage({
                          id: "views.platform.graf.2.bloc.4.subtitle",
                        })}
                      </AutomobiltyContentTitle>
                      <AutomobiltyContent color={color3_2}>
                        <Row align={"stretch"} justify="space-between">
                          <Col span={4}>
                            <Graf2ItemWrapper>
                              {intl.formatMessage({
                                id: "views.platform.graf.2.bloc.4.item.1",
                              })}
                              <EnVoitureSimone />
                            </Graf2ItemWrapper>
                          </Col>
                          <Col span={4}>
                            <Graf2ItemWrapper>
                              {intl.formatMessage({
                                id: "views.platform.graf.2.bloc.4.item.2",
                              })}
                              <Marcel />
                            </Graf2ItemWrapper>
                          </Col>
                          <Col span={4}>
                            <Graf2ItemWrapper>
                              {intl.formatMessage({
                                id: "views.platform.graf.2.bloc.4.item.3",
                              })}
                              <Ucar />
                            </Graf2ItemWrapper>
                          </Col>
                          <Col span={4}>
                            <Graf2ItemWrapper>
                              {intl.formatMessage({
                                id: "views.platform.graf.2.bloc.4.item.4",
                              })}
                              <FleetWay />
                            </Graf2ItemWrapper>
                          </Col>
                          <Col span={4}>
                            <Graf2ItemWrapper>
                              {intl.formatMessage({
                                id: "views.platform.graf.2.bloc.4.item.5",
                              })}
                              <CosmobilisMobility />
                            </Graf2ItemWrapper>
                          </Col>
                        </Row>
                      </AutomobiltyContent>
                    </Col>
                  </Row>
                </AutomobiltyContentWrapper>
              </AutomobiltyWrapper>
            </>
          )}
        </>
      )}
    </GlobalWrapper>
  );
}

export default Home;
/*
    {displayOne ? <Img src={img[0]} /> : <Img src={img[1]} />}
    
    <AutomobiltyWrapper>
        <AutomobiltyTitle>#automobility platform</AutomobiltyTitle>
        <AutomobiltyContentWrapper>
          <Row gutter={10} align={"stretch"}>
            <Col span={4} color={color2_2}>
              <AutomobiltyContentTitle color={color2_1}>
                Sourcing
              </AutomobiltyContentTitle>
              <AutomobiltyContent color={color2_2}>dbjedjbd</AutomobiltyContent>
            </Col>
            <Col span={20}>
              <AutomobiltyContentTitle color={color3_1}>
                #AUTOmobility solutions designer and AGREGATOR
              </AutomobiltyContentTitle>
              <AutomobiltyContent color={color3_2}></AutomobiltyContent>
              <AutomobiltyContentTitle color={color4_1}>
                OPEN AND INTEGRATED SAAS PLATFORM
              </AutomobiltyContentTitle>
              <AutomobiltyContent color={color4_2}></AutomobiltyContent>
            </Col>
          </Row>
        </AutomobiltyContentWrapper>
      </AutomobiltyWrapper>
*/
