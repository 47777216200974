export const prefix = "auth/";
export const prefixFuzio = "fuzio/";

export const SIGN_IN = `${prefix}SIGN_IN`;
export const CHECK_TOKEN = `${prefix}CHECK_TOKEN`;
export const SET_TOKEN = `${prefix}SET_TOKEN`;
export const SET_PHONE = `${prefix}SET_PHONE`;
export const CHECK_CODE = `${prefix}CHECK_CODE`;
export const GET_CONTENT = `${prefixFuzio}GET_CONTENT`;

export const SET_SCROLLED = `${prefixFuzio}SET_SCROLLED`;

export const SET_LANG = `${prefixFuzio}SET_LANG`;
export const SET_SHOW_LANG_HEADER = `${prefixFuzio}SET_SHOW_LANG_HEADER`;
export const SET_WHITE_MENU = `${prefixFuzio}SET_WHITE_MENU`;
export const TOGGLE_MENU = `${prefixFuzio}TOGGLE_MENU`;
export const SEND_CODE = `${prefixFuzio}SEND_CODE`;

export const GET_PDF = `${prefixFuzio}GET_PDF`;
