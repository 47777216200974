import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { toggleMenuColor } from "core/store/actions";
import useWindowSize from "hooks/utils/useWindowSize";
const img1_en = require("images/roadmap/en/img-1.png");
const img1_fr = require("images/roadmap/fr/img-1.png");

const img_en = [img1_en];
const img_fr = [img1_fr];

const GlobalWrapper = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  min-height: ${(props) => props.size.height}px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  padding: ${isMobile ? "70px 0" : "70px"};
`;

const Img = styled.img`
  max-width: ${isMobile ? 100 : 90}%;
`;

const PageTitle = styled.h3`
  font-size: ${isMobile ? 22 : 25}px;
  text-align: center;
  max-width: 75%;
  margin: 0 auto 30px;
  span {
    font-weight: 800;
  }
`;

function Home() {
  const intl = useIntl();
  const size = useWindowSize();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.fuzio.lang);

  const img = lang === "fr_FR" ? img_fr : img_en;
  useLayoutEffect(() => {
    dispatch(toggleMenuColor(false));
  }, []);

  return (
    <GlobalWrapper size={size}>
      <PageTitle>
        {intl.formatMessage(
          { id: "views.roadmap.pageTitle" },
          {
            span: (...chunks) => <span>{chunks}</span>,
          }
        )}
      </PageTitle>
      <Img src={img[0]} />
    </GlobalWrapper>
  );
}

export default Home;
