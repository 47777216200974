import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";

import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { useIntl } from "react-intl";
import { isMobile } from "react-device-detect";
import useWindowSize from "hooks/utils/useWindowSize";
import { Space } from "antd";

import { ReactComponent as Circle2 } from "assets/svg/Circle2.svg";
import { toggleMenuColor } from "core/store/actions";

const img1_en = require("images/tech/en/img-1.png");
const img1_fr = require("images/tech/fr/img-1.png");

const img_en = [img1_en];
const img_fr = [img1_fr];

const GlobalWrapper = styled.div`
  position: relative;
  flex: 1;
  display: flex;
  width: 100%;
  min-height: ${(props) => props.size.height}px;
  background: ${(props) => props.theme.colors.primary.background};
  color: ${(props) => props.theme.colors.primary.foreground};
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  padding: ${isMobile ? "70px 10px" : "70px"};
`;

const Title = styled.h1`
  font-size: ${isMobile ? 36 : 76}px;
  font-weight: 900;
  text-transform: uppercase;
`;

const SubTitle = styled.div`
  font-size: ${isMobile ? 36 : 46}px;
  font-weight: ${(props) => (props.vertical ? 600 : 300)};
  margin: 0 0 ${isMobile ? 50 : 100}px 0;
  text-align: center;
`;

const SubTitle1 = styled.div`
  font-size: ${isMobile ? 20 : 36}px;
  font-weight: 300;
  padding: 5px ${isMobile ? 15 : 30}px;
  border-right: ${(props) => (props.last || props.vertical ? 0 : 1)}px solid
    ${(props) => props.theme.colors.primary.foreground};
  border-left: ${(props) => (!props.vertical ? 0 : 1)}px solid
    ${(props) => props.theme.colors.primary.foreground};
  margin: 0 0 10px 0;
`;

const SubTitle2 = styled.div`
  font-size: ${isMobile ? 20 : 20}px;
  font-weight: 800;
  padding: 5px ${isMobile ? 15 : 20}px;
  border-left: 1px solid ${(props) => props.theme.colors.primary.foreground};
  margin: 0 0 10px 0;
`;

const Wording = styled.div`
  font-size: ${isMobile ? 18 : 16}px;
  font-weight: 300;
  text-align: justify;
`;

const AnimatedWrapper = styled.div`
  position: relative;
  height: 90%;
  top: 70px;
  width: 100%;
  flex: 1;
  display: flex;
`;

const AnimatedTextWrapper = styled.div`
  position: absolute;
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 320px;
  height: 150px;
  margin-top: -75px;
  margin-left: -160px;
`;

const AnimatedTextWrapper2 = styled(AnimatedTextWrapper)``;

const Blob = styled.div``;

const Animated = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  svg {
    cursor: pointer;
    fill: ${(props) => props.theme.colors.primary.foreground};
    height: ${(props) => props.size.height / 2}px;
    transition: all 400ms;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -${(props) => props.size.height / 4 - 30}px;
    margin-left: -${(props) => props.size.height / 4 + 40}px;
    transform: scale(${(props) => (props.active ? 1.6 : 1)});
  }
  ${Blob} {
    display: ${(props) => (props.active ? "none" : "block")};
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 5px;
    margin-left: -35px;
  }
  ${AnimatedTextWrapper} {
    opacity: ${(props) => (props.active ? "0%" : "100%")};
    transition: all 400ms;
  }
  ${AnimatedTextWrapper2} {
    opacity: ${(props) => (props.active ? "100%" : "0%")};
    transition: all 400ms;
  }
`;

const Legend = styled.div`
  font-size: ${isMobile ? 10 : 12}px;
  font-weight: 300;
  font-style: italic;
`;

function Home() {
  const size = useWindowSize();
  const dispatch = useDispatch();
  const intl = useIntl();
  const lang = useSelector((state) => state.fuzio.lang);

  const img = lang === "fr_FR" ? img_fr : img_en;

  const [active, setActive] = useState(false);
  const toggleActive = () => {
    setActive(!active);
  };

  useLayoutEffect(() => {
    dispatch(toggleMenuColor(true));
  }, []);

  const Mouse = () => {
    return (
      <div className="mouse" style={{ position: "absolute", bottom: 60 }}></div>
    );
  };
  return (
    <>
      <GlobalWrapper size={size}>
        <Title>{intl.formatMessage({ id: "views.vision.Title" })}</Title>
        <SubTitle>
          {intl.formatMessage({ id: "views.vision.SubTitle" })}
        </SubTitle>
        <Space>
          <SubTitle1>
            {intl.formatMessage({ id: "views.vision.SubTitle1.1" })}
          </SubTitle1>
          <SubTitle1>
            {intl.formatMessage({ id: "views.vision.SubTitle1.2" })}
          </SubTitle1>
          <SubTitle1 last>
            {intl.formatMessage({ id: "views.vision.SubTitle1.3" })}
          </SubTitle1>
        </Space>
        <Mouse />
      </GlobalWrapper>
      <GlobalWrapper size={size}>
        <SubTitle vertical>
          {intl.formatMessage({ id: "views.vision.2.SubTitle" })}
        </SubTitle>
        <Space direction="vertical">
          <SubTitle1 vertical>
            {intl.formatMessage({ id: "views.vision.2.SubTitle1.1" })}
          </SubTitle1>
          <SubTitle1 vertical>
            {intl.formatMessage({ id: "views.vision.2.SubTitle1.2" })}
          </SubTitle1>
          <SubTitle1 vertical>
            {intl.formatMessage({ id: "views.vision.2.SubTitle1.3" })}
          </SubTitle1>
          <SubTitle1 vertical>
            {intl.formatMessage({ id: "views.vision.2.SubTitle1.4" })}
          </SubTitle1>
          <SubTitle1 vertical>
            {intl.formatMessage({ id: "views.vision.2.SubTitle1.5" })}
          </SubTitle1>
        </Space>
        <Mouse />
      </GlobalWrapper>
      <GlobalWrapper size={size}>
        <SubTitle vertical>
          {intl.formatMessage({ id: "views.vision.3.SubTitle" })}
        </SubTitle>
        <Space direction="vertical">
          <SubTitle1 vertical>
            {intl.formatMessage({ id: "views.vision.3.SubTitle1.1" })}
          </SubTitle1>
          <SubTitle1 vertical>
            {intl.formatMessage({ id: "views.vision.3.SubTitle1.2" })}
          </SubTitle1>
          <SubTitle1 vertical>
            {intl.formatMessage({ id: "views.vision.3.SubTitle1.3" })}
          </SubTitle1>
        </Space>
        <Mouse />
      </GlobalWrapper>
      <GlobalWrapper size={size}>
        {isMobile ? (
          <Space direction="vertical" size="large">
            <div>
              <SubTitle2>
                {intl.formatMessage({ id: "views.vision.3.SubTitle1" })}
              </SubTitle2>
              <Wording>
                {intl.formatMessage({ id: "views.vision.3.wording.1" })}
              </Wording>
            </div>
            <div>
              <SubTitle2>
                {intl.formatMessage({ id: "views.vision.3.SubTitle2" })}
              </SubTitle2>
              <Wording>
                {intl.formatMessage({ id: "views.vision.3.wording.2" })}
              </Wording>
            </div>
            <div>
              <SubTitle2>
                {intl.formatMessage({ id: "views.vision.3.SubTitle3" })}
              </SubTitle2>
              <Wording>
                {intl.formatMessage({ id: "views.vision.3.wording.3" })}
              </Wording>
            </div>
            <div>
              <SubTitle2>
                {intl.formatMessage({ id: "views.vision.3.SubTitle4" })}
              </SubTitle2>
              <Wording>
                {intl.formatMessage({ id: "views.vision.3.wording.4" })}
              </Wording>
            </div>
          </Space>
        ) : (
          <>
            <AnimatedWrapper>
              <Animated active={active} size={size}>
                <Blob className="blob" />
                <AnimatedTextWrapper style={{ top: "5%", left: "50%" }}>
                  <SubTitle2>
                    {intl.formatMessage({ id: "views.vision.3.SubTitle1" })}
                  </SubTitle2>
                  <Wording>
                    {intl.formatMessage({ id: "views.vision.3.wording.1" })}
                  </Wording>
                </AnimatedTextWrapper>
                <AnimatedTextWrapper style={{ top: "50%", left: "80%" }}>
                  <SubTitle2>
                    {intl.formatMessage({ id: "views.vision.3.SubTitle2" })}
                  </SubTitle2>
                  <Wording>
                    {intl.formatMessage({ id: "views.vision.3.wording.2" })}
                  </Wording>
                </AnimatedTextWrapper>
                <AnimatedTextWrapper style={{ top: "50%", left: "20%" }}>
                  <SubTitle2>
                    {intl.formatMessage({ id: "views.vision.3.SubTitle3" })}
                  </SubTitle2>
                  <Wording>
                    {intl.formatMessage({ id: "views.vision.3.wording.3" })}
                  </Wording>
                </AnimatedTextWrapper>
                <AnimatedTextWrapper2 style={{ top: "50%", left: "50%" }}>
                  <SubTitle2>
                    {intl.formatMessage({ id: "views.vision.3.SubTitle4" })}
                  </SubTitle2>
                  <Wording>
                    {intl.formatMessage({ id: "views.vision.3.wording.4" })}
                  </Wording>
                </AnimatedTextWrapper2>
                <Circle2 onClick={toggleActive} />
              </Animated>
            </AnimatedWrapper>
          </>
        )}
      </GlobalWrapper>
    </>
  );
}

export default Home;

/*


 <GlobalWrapper size={size}>
        <SubTitle vertical>
          {intl.formatMessage({ id: "views.vision.5.SubTitle" })}
        </SubTitle>
        <Img src={img[0]} />
        <Legend>{intl.formatMessage({ id: "views.vision.5.legend" })}</Legend>
      </GlobalWrapper>
*/
