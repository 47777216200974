import { useLayoutEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import VideoEarth from "./video_earth";
import VideoApp from "./video_app";

import { toggleMenuColor } from "core/store/actions";

function View() {
  const dispatch = useDispatch();

  const ref1 = useRef();

  const ref2 = useRef();

  useLayoutEffect(() => {
    dispatch(toggleMenuColor(true));
  }, []);

  const onPlay1 = () => {
    ref2.current.stop();
  };
  const onPlay2 = () => {
    ref1.current.stop();
  };
  return (
    <>
      <VideoEarth ref={ref1} onPlayVideo={onPlay1} />
      <VideoApp ref={ref2} onPlayVideo={onPlay2} />
    </>
  );
}

export default View;
/*
      
 <StyledVideo controls size={size}>
          <source src={file} type="video/mp4" />
        </StyledVideo>

*/
