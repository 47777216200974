import React from "react";

import { FormattedMessage } from "react-intl";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errorInfo: null };
  }

  /*static getDerivedStateFromError(error) {
    console.log(error)
    // Update state so the next render will show the fallback UI.
    return { errorInfo: true }
  }*/

  componentDidCatch(error, errorInfo) {
    console.log("error:", error);
    // Catch errors in any components below and re-render with error message
    this.setState({
      errorInfo,
    });
    // You can also log error messages to an error reporting service here
  }

  render() {
    const { errorInfo } = this.state;
    const { children } = this.props;

    if (errorInfo) {
      console.log("********************* errorInfo", errorInfo);
      // Error path
      return (
        <div style={{ padding: "250px" }}>
          <h2>
            <FormattedMessage id="component.error-boundary-title" />
          </h2>
          <h3>
            <FormattedMessage id="component.error-boundary-subtitle" />
          </h3>
        </div>
      );
    }

    // Normally, just render children
    return children;
  }
}

export default ErrorBoundary;
