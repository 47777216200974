import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { Button } from "antd";
import { isMobile } from "react-device-detect";

import { setLang, sendDatas } from "core/store/actions";
import { calculateAlphaColor } from "utils/colors";
import { font } from "assets/theme";

const StyledButton = styled(Button)`
  color: ${(props) => props.theme.greys.light4};
  :hover,
  :focus {
    color: ${(props) => props.theme.greys.light4};
  }
  opacity: ${(props) => (props.selected ? 100 : 50)}%;
  font-weight: ${(props) => (props.selected ? "800" : "normal")};
  font-size: ${(props) =>
    props.header
      ? isMobile
        ? font.size.lgxxs
        : font.size.lgsm
      : font.size.base}px;
  padding-top: 0px;
  padding-bottom: 0px;
`;

const Wrapper = styled.div`
  color: ${(props) =>
    !props.whiteMenu ? props.theme.greys.light4 : props.theme.greys.light4};
  ${isMobile &&
  `align-items: center;
  justify-content: center;
  display: flex;`}
  margin-top:${(props) => props.header && isMobile && `-15px`};
  font-size: ${(props) =>
    props.header
      ? isMobile
        ? font.size.lgxxs
        : font.size.lgsm
      : font.size.base}px;
`;

const Langs = (props) => {
  const { fixed, header } = props;
  const dispatch = useDispatch();

  const token = useSelector((state) => state.fuzio.token);

  const uuid = useSelector((state) => state.fuzio.uuid);
  const lang = useSelector((state) => state.fuzio.lang);

  const deviceType = useSelector((state) => state.fuzio.deviceType);
  const whiteMenu = useSelector((state) => state.fuzio.whiteMenu);
  const location = useLocation();

  const sendDatasToWs = (value) => {
    const datas = {
      token,
      url: location.pathname,
      uuid,
      lang,
      deviceType,
      event: "changeLang",
      eventValue: value,
    };
    dispatch(sendDatas(datas));
  };

  const handleChange = (value) => {
    {
      token && sendDatasToWs(value);
    }
    dispatch(setLang(value));
  };

  //return <div></div>;
  return (
    <Wrapper
      fixed={fixed}
      style={{ pointerEvents: "auto" }}
      whiteMenu={whiteMenu ? true : false}
      header={header}
    >
      <StyledButton
        header={header}
        type="link"
        onClick={() => handleChange("fr_FR")}
        selected={lang === "fr_FR"}
      >
        FR
      </StyledButton>{" "}
      |{" "}
      <StyledButton
        header={header}
        type="link"
        onClick={() => handleChange("en_GB")}
        selected={lang === "en_GB"}
      >
        EN
      </StyledButton>
    </Wrapper>
  );
};

export default Langs;
