import { createSelector } from "@reduxjs/toolkit";

const code = (state) => state.fuzio.sendCode;
const phone = (state) => state.fuzio.user.sTelephone;
const tokenOk = (state) => state.fuzio.user.bTokenVerifie;
const tokenExpire = (state) => state.fuzio.user.bTokenExpire;

export const showInputPhoneSelector = createSelector(
  [tokenOk, phone, code],
  (a, b, c) => {
    //console.log("a, b,c:", a, b);
    return !a;
  }
);
export const showRequestCodeButtonSelector = createSelector(
  [tokenOk, phone, code],
  (a, b, c) => {
    // console.log("a, b,c:", a, b, c);
    return a && b && !c;
  }
);

export const showInputCodeSelector = createSelector(
  [tokenOk, code, tokenExpire],
  (a, b, c) => {
    //console.log("showInputCodeSelector a, b, c, d:", a, !b, c);

    return a && b && c;
  }
);
