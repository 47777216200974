import tinycolor from 'tinycolor2';

export const calculateAlphaColor = (color, alpha = 0.75) =>
  tinycolor(color)
    .setAlpha(alpha)
    .toString();

export const darken = (color, amount = 10) =>
  tinycolor(color)
    .darken(amount)
    .toString();

export const isLight = color => tinycolor(color).isLight();
