import { useLayoutEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import { Tabs, Radio } from "antd";

import useWindowSize from "hooks/utils/useWindowSize";
import { toggleMenuColor } from "core/store/actions";

const img1_en = require("images/business2/en/img-1.png");
const img1_fr = require("images/business2/fr/img-1.png");

const img2_en = require("images/business2/en/img-2.png");
const img2_fr = require("images/business2/fr/img-2.png");

const img3_en = require("images/business2/en/img-3.png");
const img3_fr = require("images/business2/fr/img-3.png");

const img4_en = require("images/business2/en/img-4.png");
const img4_fr = require("images/business2/fr/img-4.png");

const img5_en = require("images/business2/en/img-5.png");
const img5_fr = require("images/business2/fr/img-5.png");

const img6_en = require("images/business2/en/img-6.png");
const img6_fr = require("images/business2/fr/img-6.png");

const img_en = [img1_en, img2_en, img3_en, img4_en, img5_en, img6_en];
const img_fr = [img1_fr, img2_fr, img3_fr, img4_fr, img5_fr, img6_fr];

const GlobalWrapper = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  min-height: ${(props) => props.size.height}px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  padding: ${isMobile ? "70px 0" : "70px"};
  position: relative;
`;

const Img = styled.img`
  max-width: ${isMobile ? 100 : 90}%;
  margin: ${isMobile ? 50 : 30}px 0;
`;

const PageTitle = styled.h3`
  font-size: ${isMobile ? 22 : 25}px;
  text-align: center;
  max-width: 75%;
  margin: 0 auto 30px;
  span {
    font-weight: 800;
  }
`;

function Home() {
  const intl = useIntl();
  const size = useWindowSize();
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.fuzio.lang);

  const [displayedContent, setDisplayedContent] = useState(1);

  const img = lang === "fr_FR" ? img_fr : img_en;

  useLayoutEffect(() => {
    dispatch(toggleMenuColor(false));
  }, []);

  const items = [
    {
      key: "1",
      label: intl.formatMessage({ id: "views.business2.screen.1.tab.1" }),
      children: <Img src={img[0]} />,
    },
    {
      key: "2",
      label: intl.formatMessage({ id: "views.business2.screen.1.tab.2" }),
      children: <Img src={img[1]} />,
    },
  ];

  const Mouse = () => {
    return (
      <div
        className="mouse_black"
        style={{ position: "absolute", bottom: 70 }}
      ></div>
    );
  };

  const tabChange = () => {
    setDisplayedContent(displayedContent === 1 ? 2 : 1);
  };
  return (
    <>
      <GlobalWrapper size={size}>
        <PageTitle>
          {intl.formatMessage(
            { id: "views.business2.screen.1.pageTitle" },
            {
              span: (...chunks) => <span>{chunks}</span>,
            }
          )}
        </PageTitle>
        <Radio.Group
          defaultValue={displayedContent}
          buttonStyle="solid"
          onChange={tabChange}
          size="large"
        >
          <Radio.Button value={1}>
            {intl.formatMessage({ id: "views.business2.screen.1.tab.1" })}
          </Radio.Button>
          <Radio.Button value={2}>
            {intl.formatMessage({ id: "views.business2.screen.1.tab.2" })}
          </Radio.Button>
        </Radio.Group>
        {displayedContent === 1 ? <Img src={img[0]} /> : <Img src={img[1]} />}
        <Mouse />
      </GlobalWrapper>
      <GlobalWrapper size={size}>
        <Img src={img[2]} />
        <Mouse />
      </GlobalWrapper>
      <GlobalWrapper size={size}>
        <Img src={img[3]} />
        <Mouse />
      </GlobalWrapper>
      <GlobalWrapper size={size}>
        <Img src={img[4]} />
        <Mouse />
      </GlobalWrapper>
      <GlobalWrapper size={size}>
        <Img src={img[5]} />
      </GlobalWrapper>
    </>
  );
}

export default Home;
