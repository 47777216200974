import { useState } from "react";
import { Menu } from "antd";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { HomeOutlined } from "@ant-design/icons";
import { Button, Drawer } from "antd";

import { useIntl } from "react-intl";
import { ROUTES } from "constants/routes";
import theme, { font } from "assets/theme";

const StyledMenu = styled(Menu)`
  justify-content: center;
  background: ${theme.colors.primary.background};
  ${isMobile &&
  `
  font-size:${font.size.lgsm}px;
  `}
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    background: ${(props) => props.theme.colors.primary.background};
  }
`;

const ButtonWrapper = styled.div`
  ${(props) =>
    !props.whiteMenu &&
    `
  .hamburger-inner{
    background-color:${props.theme.colors.primary.background};
    :after, :before{
      background:${props.theme.colors.primary.background}
    }
  }
  `}
  position:absolute;
  z-index: 10002;
`;

const FooterTitle = styled.h5`
  font-size: 30px;
  font-weight: 900;
  margin: 0 0 20px 0;
`;

function MenuComp() {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  const intl = useIntl();

  const toggleDrawer = () => {
    setOpen(!open);
  };
  const onClose = () => {
    setOpen(false);
  };

  const menuItems = Object.values(ROUTES).map((el, index) => ({
    label: (
      <Link to={el.path} style={{ textTransform: "uppercase" }}>
        {!isMobile && index === 0 ? (
          <HomeOutlined />
        ) : (
          intl.formatMessage({ id: `component.menu.${el.key}` })
        )}
      </Link>
    ),
    key: el.path,
  }));

  const RenderMenu = (props) => {
    const { mode } = props;
    return (
      <StyledMenu
        theme="dark"
        mode={mode}
        items={menuItems}
        selectedKeys={[location.pathname]}
        onSelect={onClose}
      />
    );
  };
  if (isMobile) {
    return (
      <>
        <StyledDrawer
          placement="right"
          onClose={onClose}
          open={open}
          closable={false}
          width="100%"
        >
          <div>
            <FooterTitle>C O S M (O) B I L I S</FooterTitle>
          </div>
          <RenderMenu mode="vertical" />
        </StyledDrawer>
        <ButtonWrapper whiteMenu>
          <button
            className={`hamburger hamburger--collapse ${
              open ? "is-active" : ""
            }`}
            type="button"
            aria-label="Menu"
            aria-controls="navigation"
            aria-expanded={open ? "true" : "false"}
            onClick={toggleDrawer}
            style={{ pointerEvents: "auto" }}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner"></span>
            </span>
          </button>
        </ButtonWrapper>
      </>
    );
  }
  return <RenderMenu mode="horizontal" />;
}

export default MenuComp;
