import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import {
  SIGN_IN,
  SET_PHONE,
  CHECK_CODE,
  SET_TOKEN,
  CHECK_TOKEN,
  GET_CONTENT,
  SET_SCROLLED,
  SET_LANG,
  SET_SHOW_LANG_HEADER,
  SEND_CODE,
  SET_WHITE_MENU,
  TOGGLE_MENU,
  GET_PDF,
} from "./constants";

import { postRequest } from "utils/fetchAxios";

const SIGN_IN_URL = "check-token-fuzio-project";
const CHECK_TOKEN_URL = "check-token-fuzio-project";
const SET_PHONE_URL = "get-code-fuzio-project";
const CHECK_CODE_URL = "check-code-fuzio-project";
const GET_CONTENT_URL = "get-content-fuzio-project";
const GET_PDF_URL = "get-pdf-fuzio-project";

export const signIn = createAsyncThunk(SIGN_IN, async (payload) => {
  const response = await postRequest(SIGN_IN_URL, {
    sToken: payload.token,
    url: payload.url,
  });
  return response;
});

export const postPhone = createAsyncThunk(SET_PHONE, async (payload) => {
  const response = await postRequest(SET_PHONE_URL, {
    sToken: payload.token,
    sTelephone: payload.phone,
  });
  return response;
});

export const postCode = createAsyncThunk(CHECK_CODE, async (payload) => {
  const response = await postRequest(CHECK_CODE_URL, {
    sToken: payload.token,
    sCode: payload.code,
  });
  return response;
});

export const setToken = createAction(SET_TOKEN);
export const getContent = createAsyncThunk(GET_CONTENT, async (payload) => {
  const response = await postRequest(GET_CONTENT_URL, {
    sToken: payload.token,
  });
  console.log("response:", response);
  return response;
});

export const sendDatas = createAsyncThunk(CHECK_TOKEN, async (payload) => {
  const response = await postRequest(CHECK_TOKEN_URL, {
    sToken: payload.token,
    sCode: payload.code,
    url: payload.url,
    uuid: payload.uuid,
    lang: payload.lang,
    deviceType: payload.deviceType,
    event: payload.event,
    eventValue: payload.eventValue,
  });
  return response;
});

export const getPdfDatas = createAsyncThunk(GET_PDF, async (payload) => {
  const response = await postRequest(GET_PDF_URL, {
    sToken: payload.token,
    sCode: payload.token,
    sLangue: payload.lang.slice(0, -3),
    idPdf: payload.idPdf,
  });
  return response;
});

export const setScrolled = createAction(SET_SCROLLED);
export const setLang = createAction(SET_LANG);
export const showLangHeader = createAction(SET_SHOW_LANG_HEADER);
export const sendCode = createAction(SEND_CODE);
export const toggleMenuColor = createAction(SET_WHITE_MENU);
export const toggleMenu = createAction(TOGGLE_MENU);
