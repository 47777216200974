const theme = {
  name: "light",
  greys: {
    dark1: "#000000",
    dark2: "#1c1c1c",
    dark3: "#c3c3c3",
    light2: "#efefef",
    light3: "#f6f6f6",
    light4: "#ffffff",
  },
  colors: {
    success: { background: "#52c41a", foreground: "#ffffff" },
    warning: { background: "#faad14", foreground: "#ffffff" },
    error: { background: "#f5222d", foreground: "#ffffff" },
    primary: {
      //background: "#177ddc",
      background: "#310b2e",
      foreground: "#ffffff",
    },
    secondary: {
      background: "#809dcc",
      foreground: "#ffffff",
    },
  },
};

export default theme;

export const font = {
  /*family: "brandon-grotesque-rg",
  family2: "brandon-grotesque-rg",*/
  family: "LatoWeb",
  familyBold: "LatoWebBold",
  size: {
    xxs: 7, //11px
    xs: 8, //13px
    sm: 10, //15px
    base: 12, //17px
    lg: 14, //19px
    lgxxs: 17, //22px
    lgxs: 22, //27px
    lgsm: 26, //31px
    lgxl: 31, //36px
    lgxxl: 37, //42px
  },
  weight: {
    light: 300,
    base: "normal",
    bold: 700,
    black: 900,
  },
};

export const borderRadius = {
  sm: "5px",
  base: "10px",
  lg: "20px",
};

export const paddings = {
  xs: "5px",
  sm: "10px",
  base: "15px",
  lg: "25px",
};

export const margins = {
  xs: "5px",
  sm: "10px",
  base: "20px",
  lg: "30px",
};
