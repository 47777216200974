import { createReducer } from "@reduxjs/toolkit";
import uuid from "react-uuid";
import { isMobile, isTablet, isBrowser } from "react-device-detect";

import {
  signIn,
  postPhone,
  postCode,
  getContent,
  setToken,
  sendDatas,
  setScrolled,
  setLang,
  showLangHeader,
  sendCode,
  toggleMenuColor,
  toggleMenu,
  getPdfDatas,
} from "./actions";

const initialState = {
  uuid: null,
  deviceType: null,
  loading: false,
  error: null,
  token: null,
  content: null,
  lang: "en_GB",
  // showLangHeader: true,
  whiteMenu: true,
  //menuOpened: false,
  //sendCode: false,
  //content,
  user: {
    sCivilite: null,
    sNom: null,
    sPrenom: null,
    sTelephone: null,
    bTokenVerifie: false,
    bTokenExpire: true,
    //bTokenVerifie: true,
    // bTokenExpire: false,
    sCode: null,
  },
  scrolled: false,
  pdfDatas: null,
};

const content = require("./content.json");

function isPendingAction(action) {
  return action.type.endsWith("/pending");
}

function isRejectAction(action) {
  return action.type.endsWith("/rejected");
}

const deviceType =
  (isMobile && "mobile") || (isTablet && "tablet") || (isBrowser && "desktop");

const fuzioReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(signIn.fulfilled, (state, action) => {
      state.loading = false;
      state.user = { ...state.user, ...action.payload };
      state.content = null;
      state.sendCode = !action.payload.bTokenExpire;
    })
    .addCase(postPhone.fulfilled, (state, action) => {
      state.loading = false;
      state.user = { ...state.user, ...action.payload };
    })
    .addCase(postCode.fulfilled, (state, action) => {
      state.loading = false;
      state.user = { ...state.user, ...action.payload };
    })
    .addCase(getContent.fulfilled, (state, action) => {
      const { aContenu, ...rest } = action.payload;
      // console.log("aContenu:", aContenu);

      state.content = aContenu;
      //state.content = { ...content };

      state.user = { ...state.user, ...rest };
      state.loading = false;
    })
    .addCase(setToken, (state, action) => {
      state.token = action.payload;
      state.uuid = uuid();
      state.deviceType = deviceType;
    })
    .addCase(sendDatas.fulfilled, (state, action) => {
      state.loading = false;
      state.user = { ...state.user, ...action.payload }; //
      state.sendCode = !action.payload.bTokenExpire;
    })
    .addCase(setScrolled, (state, action) => {
      state.scrolled = action.payload;
    })
    .addCase(setLang, (state, action) => {
      state.lang = action.payload;
    })
    .addCase(showLangHeader, (state, action) => {
      state.showLangHeader = action.payload;
    })
    .addCase(sendCode, (state, action) => {
      state.sendCode = action.payload;
    })
    .addCase(toggleMenuColor, (state, action) => {
      state.whiteMenu = action.payload;
    })
    .addCase(toggleMenu, (state, action) => {
      state.menuOpened = action.payload;
    })
    .addCase(getPdfDatas.fulfilled, (state, action) => {
      state.loading = false;

      state.pdfDatas = action.payload.pdf;
    })
    .addCase(getPdfDatas, (state, action) => {
      state.loading = true;

      state.pdfDatas = null;
    })
    // Global
    .addMatcher(isPendingAction, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addMatcher(isRejectAction, (state, action) => {
      state.loading = false;
      state.error = action.error.message;

      if (action.error.message === "Token inconnu.") {
        state.content = null;
        state.sendCode = false;
        state.lang = "en_GB";
      }
    });
});

export default fuzioReducer;
