import axios from "axios";

const URL_API =
  //"http://damien.bidaut.bee2link.local/ws4you/public/application/s-custom";

  "https://ws4you.webapp4you.eu/application/s-custom";

const TIME_OUT = 30000;

export const postRequest = (url, params) => {
  //console.log("params:", params);
  const body = {
    Authentification: {
      iMode: 11,
    },
    ...params,
  };

  return new Promise((resolve, reject) => {
    axios({
      baseURL: URL_API,
      method: "post",
      url,
      data: JSON.stringify(body),
      timeout: TIME_OUT,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        const { data } = response;
        //console.log(response);
        if (data) {
          if (data.error) {
            if (data.error.message) {
              reject(data.error.message);
            }

            reject(data.error);
          } else {
            resolve(data);
          }
        } else {
          // reject('Aucune donnée');
        }
      })
      .catch((error) => {
        console.log("error axios", error);
        reject();
      })
      .finally();
  });
};
