import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import useWindowSize from "hooks/utils/useWindowSize";
import { useIntl } from "react-intl";
import { isMobile } from "react-device-detect";
import styled from "styled-components";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Row, Col } from "antd";
import { font } from "assets/theme";

import { ReactComponent as Link } from "assets/svg/Link.svg";
import { ReactComponent as Check } from "assets/svg/Check.svg";
import { ReactComponent as Factory } from "assets/svg/Factory.svg";
import { ReactComponent as Group } from "assets/svg/Group.svg";
import { ReactComponent as Gift } from "assets/svg/Gift.svg";
import { ReactComponent as Heart } from "assets/svg/Heart.svg";
import { ReactComponent as Share2 } from "assets/svg/Share2.svg";
import { ReactComponent as Arrow2 } from "assets/svg/Arrow2.svg";
import { ReactComponent as Wallet } from "assets/svg/Wallet.svg";
import { ReactComponent as Money } from "assets/svg/Money.svg";
import { toggleMenuColor } from "core/store/actions";

const color1 = "#b7c1cf";
const color2 = "#a489a7";
const color3 = "#e1d8e1";
const color4 = "#d9d9d9";
const color5 = "#e8e8e8";

const innerDivContentBorderWidth = "2px";
const divContentBorderWidth = "1px";

const GlobalWrapper = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  min-height: ${(props) => props.size.height}px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${isMobile ? "70px 5px" : "70px 20px"};
`;

const Title = styled.h1`
  color: ${(props) => props.theme.colors.primary.background};
  font-weight: 900;
  text-align: center;
  font-size: ${isMobile ? 16 : 20}px;
  line-height: ${isMobile ? 24 : 30}px;
  margin-top: 30px;
`;

const Disclaimer = styled.p`
  text-align: justify;
  font-size: ${font.size.lgxxs}px;
  line-height: ${isMobile ? font.size.lgxs : font.size.lgxl}px;
  margin: 10px ${isMobile ? 10 : 100}px ${isMobile ? 100 : 10}px;
`;
//   <Img src={img1} />

const TableTitle = styled.h5`
  font-size: ${font.size.lgxs}px;
  font-weight: 700;
  margin: 0;
  border-left: 2px solid black;
  padding-left: 8px;
`;

const TableContent = styled.ul`
  font-size: ${isMobile ? font.size.lg : font.size.lgxxs}px;
  font-weight: 400;

  padding: ${(props) => (props.inner ? "0 50px" : "0 20px")};
  margin: ${(props) => (props.inner && isMobile ? 5 : 10)}px 0 0 0;
`;

const IconWrapper = styled.div`
  flex-direction: column;
  display: flex;
  svg {
    width: ${isMobile ? 40 : 30}px;
    height: ${isMobile ? 40 : 30}px;
    fill: black;
  }
`;

const InnerDivContent = styled.div`
  border-style: solid;
  border-width: ${(props) => props.borderWidth};
  padding: 10px 10px 20px;
  height: ${(props) => props.height || "100%"};
`;

const DivContent = styled.div`
  border: ${divContentBorderWidth} solid white;
  background: ${(props) => props.color};
  width: 100%;
  height: 100%;

  ${InnerDivContent} {
    border-color: white;
  }
`;

const PageTitle = styled.h3`
  font-size: ${isMobile ? 22 : 25}px;
  text-align: center;
  max-width: 75%;
  margin: 0 auto 30px;
  font-weight: 600;
  span {
    font-weight: 800;
  }
`;

function Home() {
  const size = useWindowSize();
  const intl = useIntl();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(toggleMenuColor(false));
  }, []);

  return (
    <GlobalWrapper size={size}>
      <PageTitle>
        {intl.formatMessage(
          { id: "views.business.pageTitle" },
          {
            span: (...chunks) => <span>{chunks}</span>,
          }
        )}
      </PageTitle>
      <Row
        style={{ width: "100%" }}
        align="stretch"
        gutter={isMobile ? [0, 0] : [0, 0]}
      >
        <Col span={isMobile ? 24 : 9}>
          <DivContent color={color1}>
            <Row align="stretch">
              <Col span={isMobile ? 24 : 11}>
                <InnerDivContent borderWidth={"0 0 0 0"}>
                  <Row align={"top"} justify="space-between">
                    <Col>
                      <TableTitle>
                        {intl.formatMessage({ id: "views.business.1.title" })}
                      </TableTitle>
                    </Col>
                    <Col>
                      <IconWrapper>
                        <Link />
                      </IconWrapper>
                    </Col>
                  </Row>
                  <TableContent>
                    <li>{intl.formatMessage({ id: "views.business.1.1" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.1.2" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.1.3" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.1.5" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.1.6" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.1.7" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.1.8" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.1.9" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.1.10" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.1.11" })}</li>
                  </TableContent>
                </InnerDivContent>
              </Col>
              <Col span={isMobile ? 24 : 13}>
                <InnerDivContent
                  borderWidth={
                    isMobile
                      ? `${innerDivContentBorderWidth} 0  ${innerDivContentBorderWidth}`
                      : `0 0 0 ${innerDivContentBorderWidth}`
                  }
                  height={"50%"}
                >
                  <Row align={"top"} justify="space-between">
                    <Col>
                      <TableTitle>
                        {intl.formatMessage({ id: "views.business.2.title" })}
                      </TableTitle>
                    </Col>
                    <Col>
                      <IconWrapper>
                        <Check />
                      </IconWrapper>
                    </Col>
                  </Row>
                  <TableContent>
                    <li>{intl.formatMessage({ id: "views.business.2.1" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.2.2" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.2.3" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.2.4" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.2.5" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.2.6" })}</li>
                  </TableContent>
                </InnerDivContent>
                <InnerDivContent
                  height={"50%"}
                  borderWidth={
                    isMobile
                      ? 0
                      : `${innerDivContentBorderWidth} 0 0 ${innerDivContentBorderWidth}`
                  }
                >
                  <Row align={"top"} justify="space-between">
                    <Col>
                      <TableTitle>
                        {intl.formatMessage({ id: "views.business.3.title" })}
                      </TableTitle>
                      <TableContent>
                        <li>
                          {intl.formatMessage({ id: "views.business.3.1" })}
                        </li>
                        <li>
                          {intl.formatMessage({ id: "views.business.3.2" })}
                        </li>
                        <li>
                          {intl.formatMessage({ id: "views.business.3.3" })}
                        </li>
                        <li>
                          {intl.formatMessage({ id: "views.business.3.4" })}
                        </li>
                        <li>
                          {intl.formatMessage({ id: "views.business.3.5" })}
                        </li>
                        <li>
                          {intl.formatMessage({ id: "views.business.3.6" })}
                        </li>
                        <li>
                          {intl.formatMessage({ id: "views.business.3.7" })}
                        </li>
                      </TableContent>
                    </Col>
                    <Col>
                      <IconWrapper>
                        <Factory />
                        <Group />
                      </IconWrapper>
                    </Col>
                  </Row>
                </InnerDivContent>
              </Col>
            </Row>
          </DivContent>
        </Col>
        <Col span={isMobile ? 24 : 5}>
          <DivContent color={color2}>
            <InnerDivContent borderWidth={"0 0 0 0"}>
              <Row align={"top"} justify="space-between">
                <Col>
                  <TableTitle>
                    {intl.formatMessage({ id: "views.business.4.title" })}
                  </TableTitle>
                </Col>
                <Col>
                  <IconWrapper>
                    <Gift />
                  </IconWrapper>
                </Col>
              </Row>
              <TableContent>
                <li>{intl.formatMessage({ id: "views.business.4.1" })}</li>
                <li>{intl.formatMessage({ id: "views.business.4.2" })}</li>
                <TableContent inner>
                  <li>{intl.formatMessage({ id: "views.business.4.3" })}</li>
                  <li>{intl.formatMessage({ id: "views.business.4.4" })}</li>
                  <li>{intl.formatMessage({ id: "views.business.4.5" })}</li>
                  <li>{intl.formatMessage({ id: "views.business.4.6" })}</li>
                  <li>{intl.formatMessage({ id: "views.business.4.7" })}</li>
                  <li>{intl.formatMessage({ id: "views.business.4.8" })}</li>
                  <li>{intl.formatMessage({ id: "views.business.4.9" })}</li>
                  <li>{intl.formatMessage({ id: "views.business.4.10" })}</li>
                </TableContent>
              </TableContent>
            </InnerDivContent>
          </DivContent>
        </Col>
        <Col span={isMobile ? 24 : 10}>
          <DivContent color={color3}>
            <Row style={{ height: "100%" }} align="stretch">
              <Col span={isMobile ? 24 : 12}>
                <InnerDivContent
                  borderWidth={
                    isMobile
                      ? `0 0 ${innerDivContentBorderWidth} 0`
                      : `0 ${innerDivContentBorderWidth} 0 0`
                  }
                >
                  <Row align={"middle"} justify="space-between">
                    <Col>
                      <TableTitle>
                        {intl.formatMessage({ id: "views.business.5.title" })}
                      </TableTitle>
                    </Col>
                    <Col>
                      <IconWrapper>
                        <Heart />
                      </IconWrapper>
                    </Col>
                  </Row>
                  <TableContent>
                    <li>{intl.formatMessage({ id: "views.business.5.1" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.5.2" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.5.3" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.5.4" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.5.5" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.5.6" })}</li>
                  </TableContent>
                </InnerDivContent>
              </Col>
              <Col span={isMobile ? 24 : 12}>
                <InnerDivContent borderWidth={`0 0 0 0`}>
                  <Row align={"middle"} justify="space-between">
                    <Col>
                      <TableTitle>
                        {intl.formatMessage({ id: "views.business.6.title" })}
                      </TableTitle>
                    </Col>
                    <Col>
                      <IconWrapper>
                        <Share2 />
                      </IconWrapper>
                    </Col>
                  </Row>
                  <TableContent>
                    <li>{intl.formatMessage({ id: "views.business.6.1" })}</li>
                    <li> {intl.formatMessage({ id: "views.business.6.2" })}</li>
                    <li> {intl.formatMessage({ id: "views.business.6.3" })}</li>
                  </TableContent>
                </InnerDivContent>
              </Col>
              <Col span={isMobile ? 24 : 12}>
                <InnerDivContent
                  borderWidth={
                    isMobile
                      ? `${innerDivContentBorderWidth} 0 0 0`
                      : `${innerDivContentBorderWidth} ${innerDivContentBorderWidth} 0 0`
                  }
                >
                  <Row align={"middle"} justify="space-between">
                    <Col>
                      <TableTitle>
                        {intl.formatMessage({ id: "views.business.7.title" })}
                      </TableTitle>
                    </Col>
                    <Col>
                      <IconWrapper>
                        <Arrow2 />
                      </IconWrapper>
                    </Col>
                  </Row>
                  <TableContent>
                    <li>{intl.formatMessage({ id: "views.business.7.1" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.7.2" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.7.3" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.7.4" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.7.5" })}</li>
                  </TableContent>
                </InnerDivContent>
              </Col>
              <Col span={isMobile ? 0 : 12}>
                <InnerDivContent borderWidth={isMobile ? 0 : `0 0 0 0`}>
                  &nbsp;
                </InnerDivContent>
              </Col>
            </Row>
          </DivContent>
        </Col>
        <Col span={24}>
          <Row align={"stretch"} gutter={0}>
            <Col span={isMobile ? 24 : 12}>
              <DivContent color={color4}>
                <InnerDivContent borderWidth={`0 0 0 0`}>
                  <Row align={"middle"} justify="space-between">
                    <Col>
                      <TableTitle>
                        {intl.formatMessage({ id: "views.business.8.title" })}
                      </TableTitle>
                    </Col>
                    <Col>
                      <IconWrapper>
                        <Wallet />
                      </IconWrapper>
                    </Col>
                  </Row>
                  <TableContent>
                    <li>{intl.formatMessage({ id: "views.business.8.1" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.8.2" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.8.3" })}</li>
                  </TableContent>
                </InnerDivContent>
              </DivContent>
            </Col>
            <Col span={isMobile ? 24 : 12}>
              <DivContent color={color5}>
                <InnerDivContent
                  borderWidth={
                    isMobile ? `${innerDivContentBorderWidth} 0 0 0` : `0 0 0 0`
                  }
                >
                  <Row align={"middle"} justify="space-between">
                    <Col>
                      <TableTitle>
                        {intl.formatMessage({ id: "views.business.9.title" })}
                      </TableTitle>
                    </Col>
                    <Col>
                      <IconWrapper>
                        <Money />
                      </IconWrapper>
                    </Col>
                  </Row>
                  <TableContent>
                    <li>{intl.formatMessage({ id: "views.business.9.1" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.9.2" })}</li>
                    <li>{intl.formatMessage({ id: "views.business.9.3" })}</li>
                  </TableContent>
                </InnerDivContent>
              </DivContent>
            </Col>
          </Row>
        </Col>
      </Row>
      <Title>
        {intl.formatMessage({ id: "views.business.title.1" })}{" "}
        <ArrowRightOutlined />{" "}
        {intl.formatMessage({ id: "views.business.title.2" })}{" "}
        <ArrowRightOutlined />{" "}
        {intl.formatMessage({ id: "views.business.title.3" })}
      </Title>
      <Disclaimer>
        Il est primordial de fonder l’avantage concurrentiel sur une combinaison
        complexe d’éléments du business model. Ainsi, l’avantage concurrentiel
        d’Apple repose sur une combinaison gagnante d’éléments technologiques et
        commerciaux portés par la Marque, dont la complexité et la cohérence
        sont très difficiles à égaler. Même en copiant un par un les éléments du
        business model d’Apple, comme des points de vente ou son processus de
        développent de produits, on ne récréera pas Apple mais une mauvaise
        copie dont la structure de coût sera prohibitive et/ou dont la
        proposition de valeur ne convaincra certainement pas les clients.
      </Disclaimer>
    </GlobalWrapper>
  );
}

export default Home;

/*


     <Table>
        <tr>
          <td rowSpan={2}>
            <Row align={"middle"} justify="space-between">
              <Col>
                <TableTitle>Key Partners</TableTitle>
              </Col>
              <Col>
                <TableIcon src={icon1} />
              </Col>
            </Row>
            <TableContent>
              <li>Constructeurs</li>
              <li>Banques</li>
              <li>Assureurs</li>
              <li>Énergéticiens</li>
            </TableContent>
            <TableContent>
              <li>Sourcing Véhicules</li>
              <li>En cours financier</li>
              <li>Services</li>
              <li>Pièces de rechange</li>
              <li>Énergie</li>
            </TableContent>
          </td>
          <td>
            <Row align={"middle"} justify="space-between">
              <Col>
                <TableTitle>Key Activities</TableTitle>
              </Col>
              <Col>
                <TableIcon src={icon2} />
              </Col>
            </Row>
            <TableContent>
              <li>Ventes</li>
              <li>Abonnements</li>
              <li>Mobilité</li>
              <li>Services</li>
              <li>Permis de conduire</li>
              <li>Logistique</li>
            </TableContent>
          </td>
          <td rowSpan={2} colspan={2}>
            <Row align={"middle"} justify="space-between">
              <Col>
                <TableTitle>Value proposition</TableTitle>
              </Col>
              <Col>
                <TableIcon src={icon3} />
              </Col>
            </Row>
            <TableContent>
              <li>One stop Shop</li>
              <li>Automobilité</li>
              <li>Locations</li>
              <li>Mobilité</li>
              <li>Services</li>
              <li>Permis de conduire</li>
              <li>Logistique</li>
            </TableContent>
          </td>
          <td>
            <Row align={"middle"} justify="space-between">
              <Col>
                <TableTitle>Customer Relationships</TableTitle>
              </Col>
              <Col>
                <TableIcon src={icon4} />
              </Col>
            </Row>
            <TableContent>
              <li>Personnal Assistance</li>
              <li>Self-service</li>
              <li>Service on-line</li>
              <li>Service off-line</li>
              <li>Automated service</li>
              <li>Communities</li>
            </TableContent>
          </td>
          <td rowSpan={2}>
            <Row align={"middle"} justify="space-between">
              <Col>
                <TableTitle>Customer Segments</TableTitle>
              </Col>
              <Col>
                <TableIcon src={icon5} size={{ width: 45 }} />
              </Col>
            </Row>
            <TableContent>
              <li>B2C : consommateurs</li>
              <li>B2B : retail & ecosystème</li>
              <li>Multi-sided platform</li>
            </TableContent>
          </td>
        </tr>
        <tr>
          <td>
            <Row align={"middle"} justify="space-between">
              <Col>
                <TableTitle>Key Ressources</TableTitle>
              </Col>
              <Col>
                <TableIcon src={icon6} size={{ width: 60 }} />
              </Col>
            </Row>
            <TableContent>
              <li>Plateforme digitale</li>
              <li>Apis</li>
              <li>Marketplace</li>
              <li>Applications</li>
              <li>Points de vente physiques</li>
              <li>Points de service physiques</li>
              <li>Produits & Marques</li>
            </TableContent>
          </td>
          <td>
            <Row align={"middle"} justify="space-between">
              <Col>
                <TableTitle>Channels</TableTitle>
              </Col>
              <Col>
                <TableIcon src={icon7} size={{ width: 60 }} />
              </Col>
            </Row>
            <TableContent>
              <li>Web2store</li>
              <li>WebInstore</li>
              <li>Purchase</li>
              <li>Delivery</li>
              <li>After Sales</li>
            </TableContent>
          </td>
        </tr>
        <tr>
          <td colSpan={3}>
            <Row align={"middle"} justify="space-between">
              <Col>
                <TableTitle>Cost Structures</TableTitle>
              </Col>
              <Col>
                <TableIcon src={icon8} size={{ width: 40 }} />
              </Col>
            </Row>
            <TableContent>
              <li>Capex R&D</li>
              <li>Business developpement staff</li>
              <li>Marketing Costs</li>
            </TableContent>
          </td>
          <td colSpan={3}>
            <Row align={"middle"} justify="space-between">
              <Col>
                <TableTitle>Revenue Streams</TableTitle>
              </Col>
              <Col>
                <TableIcon src={icon9} size={{ width: 40 }} />
              </Col>
            </Row>
            <TableContent>
              <li>
                Type : Asset sale, usage fee, SAAS, subscription fee, lending,
                renting, leasing
              </li>
              <li>
                Fixed pricing : list price, product feature, segment & volume
                dependent
              </li>
              <li>
                Dynamic pricing : Negociation, yield management, real
                time-market
              </li>
            </TableContent>
          </td>
        </tr>
      </Table>


*/
