import styled from "styled-components";
import { Card } from "antd";

import { margins, borderRadius } from "theme/";

import { calculateAlphaColor } from "utils/colors";

const StyledCard = styled(Card)`
  /*background: ${(props) =>
    props.light
      ? calculateAlphaColor(props.theme.greys.light4, 0.2)
      : calculateAlphaColor(props.theme.greys.dark1, 0.2)};
      
    color: ${(props) =>
    props.light ? props.theme.greys.dark1 : props.theme.greys.light4};
      */

  background: ${(props) => calculateAlphaColor(props.theme.greys.light4, 0.7)};

  color: ${(props) => props.theme.greys.dark1};
  border-radius: ${borderRadius.lg};

  margin: ${margins.base} 0;

  &.ant-card-bordered {
    border: 1px solid
      ${(props) => calculateAlphaColor(props.theme.greys.dark1, 0.15)};
  }
  .ant-card-actions {
    background: transparent;
    border-top: 1px solid
      ${(props) => calculateAlphaColor(props.theme.greys.dark1, 0.1)};
  }
  .ant-card-head {
    color: ${(props) => props.theme.greys.dark1};
    border-bottom: 1px solid
      ${(props) => calculateAlphaColor(props.theme.greys.dark1, 0.1)};
    font-size: 30px;
  }
`;

export default StyledCard;
