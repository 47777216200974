import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import styled from "styled-components";

import { font } from "assets/theme";

const InputElement = styled.input`
  color: ${(props) => props.theme.greys.dark1};
  max-width ${isMobile ? "54px" : "80px"};
  width:100%;
  height: ${isMobile ? "70px" : "100px"};
  margin: 0 ${isMobile ? "3px" : "10px"};
  padding: 0;
  appearance: none;
  border: ${isMobile ? "2px" : "4px"} solid
    ${(props) => props.theme.colors.primary.background};
  border-radius: 10px;
  outline: none;
  text-align: center;
  font-size: ${isMobile ? "30px" : "50px"};
  font-weight: ${font.weight.bold};
  :focus {
    border-width: ${isMobile ? "4px" : "7px"};
    /* box-shadow: 0px 0px ${isMobile ? "10px" : "20px"}
      ${(props) => props.theme.colors.primary.background};*/
  }
  transition: all 200ms linear;
`;

const Item = (props) => {
  const { onChange, id } = props;
  const [valueDisplayed, setValueDisplayed] = useState("");

  const onFocus = () => {
    setValueDisplayed("");
  };
  const oninput = (el) => {
    const { value } = el.target;

    if (isNaN(value)) {
      setValueDisplayed("");
    } else {
      if (value.length > 1) return false;
      setValueDisplayed(value);
      //if (value.length > 0 && el.target.nextElementSibling)
      //  el.target.nextElementSibling.focus();
    }
  };

  useEffect(() => {
    onChange(valueDisplayed);
  }, [valueDisplayed]);
  return (
    <InputElement
      id={id}
      type="tel"
      onInput={oninput}
      value={valueDisplayed}
      onFocus={onFocus}
    />
  );
};

export default Item;
