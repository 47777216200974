import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import useWindowSize from "hooks/utils/useWindowSize";

import { useIntl } from "react-intl";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import { Row, Col, Space } from "antd";

import { font } from "theme";

import { ReactComponent as HandTech } from "assets/svg/Hand_tech.svg";
import { ReactComponent as Cloud } from "assets/svg/Cloud.svg";
import { ReactComponent as Brain2 } from "assets/svg/Brain2.svg";
import { ReactComponent as Puzzle } from "assets/svg/Puzzle.svg";
import { ReactComponent as Lan } from "assets/svg/Lan.svg";
import { ReactComponent as Stars } from "assets/svg/Stars.svg";
import { ReactComponent as Bulb } from "assets/svg/Bulb.svg";
import { ReactComponent as Plus } from "assets/svg/Plus.svg";

import { toggleMenuColor } from "core/store/actions";

const IconWrapper = styled.div`
  flex-direction: column;
  display: flex;
  margin-top: -15px;
  margin-bottom: 20px;
  svg {
    width: ${(props) => props.size || 50}px;
    height: ${(props) => props.size || 50}px;
    margin: 0 auto;
    fill: black;
  }
`;

const GlobalWrapper = styled.div`
  background: ${(props) => props.theme.colors.primary.background};
  color: ${(props) => props.theme.colors.primary.foreground};
  svg {
    fill: ${(props) => props.theme.colors.primary.foreground};
  }
  flex: 1;
  display: flex;
  width: 100%;
  min-height: ${(props) => props.size.height}px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${isMobile ? "70px 10px" : "70px 70px"};
  position: relative;
`;
const BionicWrapper = styled.div`
  margin-bottom: 50px;
`;

const ItemTitle = styled.div`
  font-size: ${font.size.lgxs}px;
  font-weight: 500;
  margin-bottom: 5px;
  border-left: 2px solid ${(props) => props.theme.colors.primary.foreground};
  padding-left: 8px;
`;
const ItemDatas = styled.div`
  font-size: ${font.size.lgxxs}px;
  font-weight: 300;
  ul {
    margin: 0;
  }
  li {
    margin: 10px 0;
    text-align: justify;
  }
  margin-bottom: 30px;
`;

const SubTitle1 = styled.div`
  font-size: ${isMobile ? 16 : 30}px;
  font-weight: 300;
  padding: 5px ${isMobile ? 15 : 30}px;
  border-right: ${(props) => (props.last || props.vertical ? 0 : 1)}px solid
    ${(props) => props.theme.colors.primary.foreground};
  border-left: ${(props) => (!props.vertical ? 0 : 1)}px solid
    ${(props) => props.theme.colors.primary.foreground};
  margin: 0 0 10px 0;
`;

const Title2 = styled.h1`
  font-size: ${isMobile ? 40 : 50}px;
  font-weight: 900;
  text-transform: uppercase;
  text-align: center;
  margin: 0 10px;
`;
const SubTitle2 = styled.div`
  font-size: ${isMobile ? 20 : 30}px;
  font-weight: ${(props) => (props.vertical ? 500 : 300)};
  margin: ${isMobile ? 50 : 50}px 0;
  text-align: center;
`;
function Home() {
  const size = useWindowSize();
  const intl = useIntl();
  const dispatch = useDispatch();

  const Mouse = () => {
    return (
      <div className="mouse" style={{ position: "absolute", bottom: 60 }}></div>
    );
  };

  useLayoutEffect(() => {
    dispatch(toggleMenuColor(true));
  }, []);
  return (
    <>
      <GlobalWrapper size={size}>
        <Title2>
          {intl.formatMessage({ id: "views.bionic.intro.Title" })}
        </Title2>
        <SubTitle2 vertical>
          {intl.formatMessage({ id: "views.bionic.intro.SubTitle" })}
        </SubTitle2>
        <Space direction="vertical">
          <SubTitle1 vertical>
            {intl.formatMessage({ id: "views.bionic.intro.SubTitle1.1" })}
          </SubTitle1>
          <SubTitle1 vertical>
            {intl.formatMessage({ id: "views.bionic.intro.SubTitle1.2" })}
          </SubTitle1>
          <SubTitle1 vertical>
            {intl.formatMessage({ id: "views.bionic.intro.SubTitle1.3" })}
          </SubTitle1>
          <SubTitle2 vertical>
            {intl.formatMessage({ id: "views.bionic.intro.SubTitle2" })}
          </SubTitle2>
        </Space>
        <Mouse />
      </GlobalWrapper>

      <GlobalWrapper size={size}>
        <BionicWrapper>
          <Title2>{intl.formatMessage({ id: "views.bionic.2.Title" })}</Title2>
          <Title2>
            {intl.formatMessage({ id: "views.bionic.2.SubTitle" })}
          </Title2>
          <Row
            gutter={[20, isMobile ? 20 : 50]}
            justify="space-between"
            style={{ marginTop: 100 }}
          >
            <Col span={isMobile ? 24 : 12}>
              <Row justify="start" gutter={0}>
                <Col span={isMobile ? 0 : 3}>
                  <IconWrapper>
                    <Cloud />
                  </IconWrapper>
                </Col>
                <Col span={isMobile ? 24 : 19}>
                  <ItemTitle>
                    {intl.formatMessage({
                      id: "views.bionic.2.item1.ItemTitle",
                    })}
                  </ItemTitle>
                  <ItemDatas>
                    <ul>
                      <li>
                        {intl.formatMessage({
                          id: "views.bionic.2.item1.ItemDatas1",
                        })}
                      </li>
                      <li>
                        {intl.formatMessage({
                          id: "views.bionic.2.item1.ItemDatas2",
                        })}
                      </li>
                    </ul>
                  </ItemDatas>
                </Col>
              </Row>
            </Col>
            <Col span={isMobile ? 24 : 12}>
              <Row justify="start" gutter={0}>
                <Col span={isMobile ? 0 : 3}>
                  <IconWrapper>
                    <Brain2 />
                  </IconWrapper>
                </Col>
                <Col span={isMobile ? 24 : 19}>
                  <ItemTitle>
                    {intl.formatMessage({
                      id: "views.bionic.2.item2.ItemTitle",
                    })}
                  </ItemTitle>
                  <ItemDatas>
                    <ul>
                      <li>
                        {intl.formatMessage({
                          id: "views.bionic.2.item2.ItemDatas1",
                        })}
                      </li>
                      <li>
                        {intl.formatMessage({
                          id: "views.bionic.2.item2.ItemDatas2",
                        })}
                      </li>
                      <li>
                        {intl.formatMessage({
                          id: "views.bionic.2.item2.ItemDatas3",
                        })}
                      </li>
                    </ul>
                  </ItemDatas>
                </Col>
              </Row>
            </Col>
            <Col span={isMobile ? 24 : 12}>
              <Row justify="start" gutter={15}>
                <Col span={isMobile ? 0 : 3}>
                  <IconWrapper>
                    <Puzzle />
                  </IconWrapper>
                </Col>
                <Col span={isMobile ? 24 : 19}>
                  <ItemTitle>
                    {intl.formatMessage({
                      id: "views.bionic.2.item3.ItemTitle",
                    })}
                  </ItemTitle>
                  <ItemDatas>
                    <ul>
                      <li>
                        {intl.formatMessage({
                          id: "views.bionic.2.item3.ItemDatas1",
                        })}
                      </li>
                      <li>
                        {intl.formatMessage({
                          id: "views.bionic.2.item3.ItemDatas2",
                        })}
                      </li>
                      <li>
                        {intl.formatMessage({
                          id: "views.bionic.2.item3.ItemDatas3",
                        })}
                      </li>
                    </ul>
                  </ItemDatas>
                </Col>
              </Row>
            </Col>
            <Col span={isMobile ? 24 : 12}>
              <Row justify="start" gutter={15}>
                <Col span={isMobile ? 0 : 3}>
                  <IconWrapper>
                    <Lan />
                  </IconWrapper>
                </Col>
                <Col span={isMobile ? 24 : 19}>
                  <ItemTitle>
                    {intl.formatMessage({
                      id: "views.bionic.2.item4.ItemTitle",
                    })}
                  </ItemTitle>
                  <ItemDatas>
                    <ul>
                      <li>
                        {intl.formatMessage({
                          id: "views.bionic.2.item4.ItemDatas1",
                        })}
                      </li>
                    </ul>
                  </ItemDatas>
                </Col>
              </Row>
            </Col>
          </Row>
        </BionicWrapper>
        <Mouse />
      </GlobalWrapper>
      <GlobalWrapper size={size}>
        <BionicWrapper>
          <Title2>{intl.formatMessage({ id: "views.bionic.3.Title" })}</Title2>
          <Title2>
            {intl.formatMessage({ id: "views.bionic.3.SubTitle" })}
          </Title2>
          <Row
            gutter={[50, 20]}
            justify="space-around"
            style={{ marginTop: 100 }}
          >
            <Col span={isMobile ? 24 : 8}>
              <Row gutter={50} align="middle" justify="center">
                <Col span={isMobile ? 0 : 24}>
                  <IconWrapper size={90}>
                    <Stars />
                  </IconWrapper>
                </Col>
                <Col>
                  <ItemTitle>
                    {intl.formatMessage({
                      id: "views.bionic.3.item1.ItemTitle",
                    })}
                  </ItemTitle>
                  <ItemDatas>
                    <ul>
                      <li>
                        {intl.formatMessage({
                          id: "views.bionic.3.item1.ItemDatas1",
                        })}
                      </li>
                      <li>
                        {intl.formatMessage({
                          id: "views.bionic.3.item1.ItemDatas2",
                        })}
                      </li>
                      <li>
                        {intl.formatMessage({
                          id: "views.bionic.3.item1.ItemDatas3",
                        })}
                      </li>
                    </ul>
                  </ItemDatas>
                </Col>
              </Row>
            </Col>
            <Col span={isMobile ? 24 : 8}>
              <Row justify="center" gutter={50}>
                <Col span={isMobile ? 0 : 24}>
                  <IconWrapper size={90}>
                    <Bulb />
                  </IconWrapper>
                </Col>
                <Col>
                  <ItemTitle>
                    {intl.formatMessage({
                      id: "views.bionic.3.item2.ItemTitle",
                    })}
                  </ItemTitle>
                  <ItemDatas>
                    <ul>
                      <li>
                        {intl.formatMessage({
                          id: "views.bionic.3.item2.ItemDatas1",
                        })}
                      </li>
                      <li>
                        {intl.formatMessage({
                          id: "views.bionic.3.item2.ItemDatas2",
                        })}
                      </li>
                      <li>
                        {intl.formatMessage({
                          id: "views.bionic.3.item2.ItemDatas3",
                        })}
                      </li>
                    </ul>
                  </ItemDatas>
                </Col>
              </Row>
            </Col>
            <Col span={isMobile ? 24 : 8}>
              <Row justify="center" gutter={50}>
                <Col span={isMobile ? 0 : 24}>
                  <IconWrapper size={70} style={{ marginBottom: 40 }}>
                    <Plus />
                  </IconWrapper>
                </Col>
                <Col>
                  <ItemTitle>
                    {intl.formatMessage({
                      id: "views.bionic.3.item3.ItemTitle",
                    })}
                  </ItemTitle>
                  <ItemDatas>
                    <ul>
                      <li>
                        {intl.formatMessage({
                          id: "views.bionic.3.item3.ItemDatas1",
                        })}
                      </li>
                      <li>
                        {intl.formatMessage({
                          id: "views.bionic.3.item3.ItemDatas2",
                        })}
                      </li>
                    </ul>
                  </ItemDatas>
                </Col>
              </Row>
            </Col>
          </Row>
        </BionicWrapper>
      </GlobalWrapper>
    </>
  );
}

export default Home;
