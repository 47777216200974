import { Provider } from "react-redux";
import { persistStore } from "redux-persist";

import { PersistGate } from "redux-persist/integration/react";

import "antd/dist/reset.css";
import "assets/css/index.scss";

import GlobalContextProvider from "contexts/global";

import store from "./core/store";
import Routing from "core/routing";
let persistor = persistStore(store);

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <GlobalContextProvider>
          <Routing />
        </GlobalContextProvider>
      </PersistGate>
    </Provider>
  );
}

export default App;
