import { useRef, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import { useIntl } from "react-intl";
import { isMobile } from "react-device-detect";
import { useDispatch } from "react-redux";
import { Row, Col, Button } from "antd";
import { calculateAlphaColor } from "utils/colors";

import ReactJWPlayer from "react-jw-player";
import { CaretRightOutlined } from "@ant-design/icons";
import useWindowSize from "hooks/utils/useWindowSize";
import styled from "styled-components";
import { sendDatas } from "core/store/actions";

const bkg2 = require("assets/images/onepage/video/earth.jpg");

const DIV_ID = "bee2link-video-player";

const GlobalWrapper = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  min-height: ${(props) => props.size?.height}px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 64px;
`;

const VideoWrapper = styled.div`
  margin: 0 auto;
  height: 100%;
  width: 100%;
  background: #010101;
`;

const Title = styled.h2`
  font-size: ${isMobile ? 26 : 50}px;
  font-weight: 600;
  color: ${(props) => props.theme.colors.primary.foreground};
  margin: ${isMobile ? 30 : 0}px 0 30px 0;
`;

const ItemWrapper = styled.div`
  background: ${(props) =>
    calculateAlphaColor(props.theme.colors.primary.background, 0.75)};
  display: flex;
  flex: 1;
  height: 100%;
  align-items: center;
  justify-content: center;
  min-height: 70px;
  flex-direction: column;
`;

const HomePage = forwardRef((props, ref) => {
  const { onPlayVideo } = props;
  const dispatch = useDispatch();
  const intl = useIntl();
  const token = useSelector((state) => state.fuzio.token);

  const uuid = useSelector((state) => state.fuzio.uuid);
  const lang = useSelector((state) => state.fuzio.lang);
  const deviceType = useSelector((state) => state.fuzio.deviceType);

  const videoElement = useRef(null);
  let jwPlayer = null;
  //const content = useSelector((state) => state.fuzio.content);
  //const currentPage = content[lang].pages.find((el) => el.key === id).content;

  //const video = currentPage.video;

  const file = "https://fuzio-project.io/_media/Fuzio-movie_x264_SD.mp4";
  //const file = "https://fuzio-project.io/_media/cosmobilis.mov";
  const sendDatasToWs = (duree_video) => {
    const datas = {
      token,
      //url: location.pathname,
      event: "video_earth",
      eventValue: duree_video,
      uuid,
      lang,
      deviceType,
    };
    dispatch(sendDatas(datas));
  };

  const onError = (event) => {
    console.log("onError:", event);
    sendDatasToWs(`error ${event.code}`);
  };
  const onPlay = (e) => {
    onPlayVideo();
    sendDatasToWs(0);
  };
  const onTwentyFivePercent = () => {
    sendDatasToWs(25);
  };
  const onFiftyPercent = () => {
    sendDatasToWs(50);
  };
  const onSeventyFivePercent = () => {
    sendDatasToWs(75);
  };

  const onOneHundredPercent = () => {
    sendDatasToWs(100);
  };

  const size = useWindowSize();

  useImperativeHandle(ref, () => ({
    stop() {
      window?.jwplayer(DIV_ID).stop();
    },
  }));

  const onReady = () => {
    jwPlayer = window.jwplayer(DIV_ID);
  };

  const onResume = () => {
    onPlayVideo();
  };
  const playFullScreen = () => {
    if (videoElement.current) {
      window.jwplayer(DIV_ID).setFullscreen(true);
      window.jwplayer(DIV_ID).play();
      onPlayVideo();
      //videoElement.current.props.playerId.play();
    }
  };
  return (
    <GlobalWrapper>
      <Row
        align={"strech"}
        justify="space-between"
        style={{ width: "100%", height: "100%" }}
      >
        <Col span={isMobile ? 24 : 12}>
          <ItemWrapper>
            <Title>{intl.formatMessage({ id: "views.medias.title1" })}</Title>
            <Button
              type="primary"
              size="large"
              icon={<CaretRightOutlined />}
              onClick={playFullScreen}
              style={{ marginBottom: 30 }}
            >
              {intl.formatMessage({ id: "views.medias.ButtonPlayFullScreen1" })}
            </Button>
          </ItemWrapper>
        </Col>
        <Col span={isMobile ? 24 : 12}>
          {file && (
            <VideoWrapper size={size}>
              <ReactJWPlayer
                ref={videoElement}
                playerId={DIV_ID}
                playerScript="https://cdn.jwplayer.com/libraries/GWqxTRnu.js"
                playlist={[
                  {
                    file: `${file}?token=${token}`,
                    type: "mp4",
                    image: bkg2,
                  },
                ]}
                onResume={onResume}
                onError={onError}
                onPlay={onPlay}
                onTwentyFivePercent={onTwentyFivePercent}
                onFiftyPercent={onFiftyPercent}
                onSeventyFivePercent={onSeventyFivePercent}
                onOneHundredPercent={onOneHundredPercent}
                onReady={onReady}
              />
            </VideoWrapper>
          )}
        </Col>
      </Row>
    </GlobalWrapper>
  );
});

/*
     <Button
              type="primary"
              size="large"
              icon={<CaretRightOutlined />}
              onClick={playFullScreen}
            >
              {intl.formatMessage({ id: "views.medias.ButtonPlayFullScreen1" })}
            </Button>
*/
export default HomePage;
