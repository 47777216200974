import { useLayoutEffect } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { isMobile } from "react-device-detect";
import useWindowSize from "hooks/utils/useWindowSize";

import { toggleMenuColor } from "core/store/actions";
const img1 = require("images/structure/img-1.png");

const GlobalWrapper = styled.div`
  flex: 1;
  display: flex;
  width: 100%;
  min-height: ${(props) => props.size.height}px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
  padding: ${isMobile ? "70px 0" : "70px"};
`;

const Img = styled.img`
  max-width: ${isMobile ? 100 : 90}%;
`;

function Home() {
  const size = useWindowSize();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(toggleMenuColor(false));
  }, []);
  return (
    <GlobalWrapper size={size}>
      <Img src={img1} />
    </GlobalWrapper>
  );
}

export default Home;
