import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "antd";
import styled from "styled-components";
import { calculateAlphaColor } from "core/utils/colors";
import { DownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";

import { sendDatas, getPdfDatas } from "core/store/actions";
import { margins } from "assets/theme";

const IconWrapper = styled.span`
  margin: 0 0 0 10px;
  transition: all 500ms;
  font-size: 30px;
`;

const ButtonWrapper = styled.span`
  border: 1px solid
    ${(props) =>
      props.whiteMenu
        ? calculateAlphaColor(props.theme.greys.light4, 0.4)
        : calculateAlphaColor(props.theme.greys.dark1, 0.4)};
  border-radius: 80px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  transition: all 500ms;
`;

const Button = styled.a`
  margin-bottom: ${margins.base};
  position: relative;
  padding: ${isMobile ? "6px 15px" : "10px 40px"};
  display: flex;
  /*max-width: ${isMobile ? 120 : 300}px;*/
  width: 100%;
  align-items: center;
  justify-content: space-between;
  color: ${(props) => {
    return props.whiteMenu
      ? props.theme.greys.light4
      : props.theme.colors.primary.background;
  }};
  font-size: ${isMobile ? 14 : 16}px;
  line-height: 14px;
  transition: all 500ms;
  :hover {
    color: ${(props) =>
      props.whiteMenu
        ? props.theme.greys.light4
        : props.theme.colors.primary.background};
    font-size: ${isMobile ? 16 : 14}px;
    ${!isMobile &&
    `
    ${ButtonWrapper} {
      width: 70px;
      border-width: 2px;
      border-color:  ${(props) =>
        props.whiteMenu
          ? props.theme.greys.light4
          : props.theme.colors.primary.background};
    }
    ${IconWrapper} {
      margin-left: 40px;
      margin-right: -20px;
    }`}
  }
`;

const base64toBlob = (data) => {
  // Cut the prefix `data:application/pdf;base64` from the raw base 64
  /* const base64WithoutPrefix = data.substr(
    "data:application/pdf;base64,".length
  );
*/
  const bytes = atob(data);
  let length = bytes.length;
  let out = new Uint8Array(length);

  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }

  return new Blob([out], { type: "application/pdf" });
};

const GetTheDesk = () => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const token = useSelector((state) => state.fuzio.token);
  const tokenbTokenExpire = useSelector(
    (state) => !state.fuzio.user.bTokenExpire
  );

  const uuid = useSelector((state) => state.fuzio.uuid);
  const lang = useSelector((state) => state.fuzio.lang);
  const deviceType = useSelector((state) => state.fuzio.deviceType);

  const whiteMenu = useSelector((state) => state.fuzio.whiteMenu);

  const pdfDatas = useSelector((state) => state.fuzio.pdfDatas);

  const location = useLocation();

  const getTheDeck = {
    buttonText: intl.formatMessage({ id: "getTheDeskText" }),
    buttonTextSm: intl.formatMessage({ id: "getTheDeskTextSm" }),
    id: 1,
  };
  const getTheTeaser = null;
  /*{
    buttonText: intl.formatMessage({ id: "getTheTeaserText" }),
    buttonTextSm: intl.formatMessage({ id: "getTheTeaserTextSm" }),
    id: 2,
  };*/

  const [localLoading, setLocalLoading] = useState();

  const getPdfDatasFromWs = (value) => {
    const datasToSend = {
      token,
      lang,
      idPdf: value,
    };
    dispatch(getPdfDatas(datasToSend));
  };

  const sendDatasToWs = (value) => {
    const datasToSend = {
      token,
      url: location.pathname,
      uuid,
      lang,
      deviceType,
      event: "download",
      value,
    };
    dispatch(sendDatas(datasToSend));
  };

  const gotoGetTheDeck = () => {
    sendDatasToWs("Deck");
    getPdfDatasFromWs(1);
    setLocalLoading("Deck");
  };

  const gotoGetTheTeaser = () => {
    sendDatasToWs("Teaser");
    getPdfDatasFromWs(2);
    setLocalLoading("Teaser");
  };
  //return <div></div>;

  useEffect(() => {
    if (pdfDatas) {
      const fileName =
        localLoading === "Deck"
          ? `Cosmobilis_Deck_${getTheDeck?.buttonTextSm}`
          : getTheTeaser?.buttonTextSm;
      const blob = base64toBlob(pdfDatas);

      const url = window.URL || window.webkitURL;
      const link = url.createObjectURL(blob);
      var a = document.createElement("a");
      a.setAttribute("download", fileName);
      a.setAttribute("href", link);
      a.setAttribute("target", "_blank");
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      setLocalLoading();
    }
  }, [pdfDatas]);

  if (!tokenbTokenExpire) return <></>;
  return (
    <Row
      justify={"space-between"}
      gutter={30}
      style={{
        position: "absolute",
        right: isMobile ? 15 : 20,
        top: isMobile ? -55 : -70,
        width: isMobile ? "100%" : 420,
      }}
    >
      {getTheTeaser && (
        <Col span={isMobile ? 11 : 24}>
          <Button
            onClick={() => (!localLoading ? gotoGetTheTeaser() : false)}
            style={{ pointerEvents: "auto" }}
            whiteMenu={whiteMenu ? true : false}
          >
            <ButtonWrapper whiteMenu={whiteMenu ? true : false} />
            {isMobile ? getTheTeaser?.buttonTextSm : getTheTeaser?.buttonText}
            <IconWrapper>
              {localLoading && localLoading === "Teaser" ? (
                <LoadingOutlined />
              ) : (
                <DownloadOutlined />
              )}
            </IconWrapper>
          </Button>
        </Col>
      )}
      {getTheDeck && (
        <Col span={isMobile ? 11 : 24}>
          <Button
            onClick={() => (!localLoading ? gotoGetTheDeck() : false)}
            style={{ pointerEvents: "auto" }}
            whiteMenu={whiteMenu ? true : false}
          >
            <ButtonWrapper whiteMenu={whiteMenu ? true : false} />
            {isMobile ? getTheDeck?.buttonTextSm : getTheDeck?.buttonText}
            <IconWrapper>
              {localLoading && localLoading === "Deck" ? (
                <LoadingOutlined />
              ) : (
                <DownloadOutlined />
              )}
            </IconWrapper>
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default GetTheDesk;
